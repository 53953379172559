<div
  class="w-full min-h-full h-screen overflow-y-auto bg-slate-200 p-4 flex flex-col justify-start"
>
  <div class="rounded p-6 bg-white flex flex-col h-[100vh]">
    <div class="flex flex-row justify-between">
      <div class="flex flex-col justify-start">
        <h4 class="font-bold text-primary-blue text-2xl">Add Soft Service</h4>
        <p class="sub-heading-text">
          Select soft services you want to added to this business case
        </p>
      </div>
      <div>
        <mat-icon class="cursor-pointer" (click)="closeOverlay()"
          >close</mat-icon
        >
      </div>
    </div>
    <div class="mt-4">
      <div>
        <div class="flex justify-between gap-4 items-center bg-white px-2 py-4">
          <div class="flex gap-4 justify-center">
            <form class="max-w-sm">
              <mat-form-field class="search-bar" appearance="outline">
                <mat-label class="search-bar-label"
                  >Search by service name</mat-label
                >
                <input
                  type="text"
                  matInput
                  placeholder="Search"
                  [(ngModel)]="searchValue"
                  name="searchTerm"
                />
                <mat-icon class="white-icon" (click)="triggerChange()" matSuffix
                  >search</mat-icon
                >
              </mat-form-field>
            </form>

            <div class="flex items-center gap-4">
              <mat-form-field appearance="outline">
                <mat-label>Filter by category</mat-label>
                <mat-select [(ngModel)]="categoryValue">
                  @for (categories of filterCategoriesKeys; track categories) {
                  <mat-option
                    [value]="categories"
                    (click)="applyFilter(categories)"
                    >{{ categories }}</mat-option
                  >
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          @if (data.businessCaseDetails.activeStageName ===
          businessCaseStages.COST_SOFT_SERVICES &&
          data.businessCaseDetails?.activeStageStatus ===
          businessCaseStageStatus.DRAFT && isEditor ||
          data.businessCaseDetails?.activeStageName ===
          businessCaseStages.CUSTOMER_OPERATIONS_REVIEW &&
          data.businessCaseDetails?.activeStageStatus ===
          businessCaseStageStatus.REJECTED && isEditor ) {
          <div>
            <button mat-flat-button color="accent" (click)="submit()">
              Add soft services
            </button>
          </div>
          }
        </div>
      </div>
    </div>
    <div class="w-full bg-red-400 overflow-x-auto">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>
            <span class="font-semibold">Code</span>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.code }}
          </td>
        </ng-container>
        <ng-container matColumnDef="serviceName">
          <th mat-header-cell *matHeaderCellDef>Service name</th>
          <td mat-cell *matCellDef="let element">{{ element.serviceName }}</td>
        </ng-container>

        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef>Category</th>
          <td mat-cell *matCellDef="let element">
            <span class="px-2 py-1 rounded-md">{{
              transformToSentenceCase(element.category)
            }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="subCategory">
          <th mat-header-cell *matHeaderCellDef>Sub Category</th>
          <td mat-cell *matCellDef="let element">
            {{ transformToSentenceCase(element.subCategory) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="unitCost">
          <th mat-header-cell *matHeaderCellDef>Unit cost</th>
          <td mat-cell *matCellDef="let element">
            <span class="px-2 py-1 flex flex-row">
              <p class="text-primary-blue px-1">R</p>
              {{ element.unitCost }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="uom">
          <th mat-header-cell *matHeaderCellDef>UOM</th>
          <td mat-cell *matCellDef="let element">
            <span class="px-2 py-1">
              {{ transformToSentenceCase(element.unitOfMeasure) }}</span
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef>Quantity</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="px-2 py-4"
            >
              <mat-label
                matTooltip="Add quantity"
                class="flex flex-row items-center"
                >Quantity</mat-label
              >
              <input
                type="number"
                min="0"
                (keypress)="preventNegative($event)"
                placeholder="0"
                matInput
                [attr.data-id]="element.id"
                [(ngModel)]="element.quantity"
                name="quantity"
              />
            </mat-form-field>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="w-full" *matNoDataRow>
          <td [colSpan]="displayedColumns.length">
            <div
              class="h-96 flex flex-row justify-center items-center font-bold"
            >
              No Data
            </div>
          </td>
        </tr>
      </table>

      @if (dataSource.data.length > 0) {
      <mat-paginator
        [length]="resultsLength"
        [pageSize]="size"
        aria-label="Select page"
      >
      </mat-paginator>
      }
    </div>
  </div>
</div>
