import { Component, OnInit } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import {
  backOfficeRouteKeys,
  BusinessCaseHeaderComponent,
  OverlayService
} from '@bidvest/shared';
import {
  ActivatedRoute,
  ParamMap,
  Router,
  RouterModule
} from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { firstValueFrom } from 'rxjs';
import { IBusinessCaseDetails } from '../../interfaces/business-case-details.interface';
import { BusinessCaseService } from '../../services/business-case.service';
import { AssetPricingTableComponent } from '../../components/tables/asset-pricing-table/asset-pricing-table.component';
import { MatButtonModule } from '@angular/material/button';
import { AssetListService } from '../../services/asset-list.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { IBusinessCaseStageConfig } from '../../interfaces/business-case-stage-config.interface';
import { ToastrService } from 'ngx-toastr';
import { IBusinessCaseAsset } from '../../interfaces/business-case-asset.interface';
import { FormsModule } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BusinessCaseReviewPayload } from '../../interfaces/business-case-review-payload.interface';
import { BusinessCaseReviewStatus } from '../../enums/business-case-review-status.enum';
import { BusinessCaseActivityLogComponent } from '../../components/business-case-activity-log/business-case-activity-log.component';
import { IProcurementPriceList } from '../../interfaces/procurement-price-list.interface';
import { BusinessCaseStagesEnum } from '../../enums/business-case-stages.enum';
import { BusinessCaseStageStatus } from '../../enums/business-case-stage-status.enum';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ReasonForRejectionOverlayComponent
} from '../../components/dialogs/reason-for-rejection-overlay/reason-for-rejection-overlay.component';

@Component({
  selector: 'bidvest-gather-asset-price',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    BusinessCaseHeaderComponent,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    AssetPricingTableComponent,
    NgIf
  ],
  templateUrl: './gather-asset-price.component.html',
  styleUrl: './gather-asset-price.component.scss'
})
export class GatherAssetPriceComponent implements OnInit {
  public readonly routes = backOfficeRouteKeys;
  public businessCaseId!: number;
  public currentStageName!: string;
  public businessCase!: IBusinessCaseDetails;
  public verifiedAssets: Array<IBusinessCaseAsset> = [];
  public assetsForPricing: Array<IBusinessCaseAsset> = [];
  public businessCaseStagesConfigs: Array<IBusinessCaseStageConfig> = [];
  public currentPageIndex = 0;
  public pageSize = 200;
  public isRFQMode = false;
  public isEditor!: boolean;
  public isReviewer!: boolean;
  public searchInput!: string;
  public filterInput!: string;
  public filterOptions: Array<string> = ['All', 'Available', 'Missing'];
  public assetListData: any = [];
  public businessCaseStages = BusinessCaseStagesEnum;
  public businessCaseStageStatus = BusinessCaseStageStatus;

  public constructor(
    private readonly _dialog: MatDialog,
    private readonly _route: ActivatedRoute,
    private readonly _businessCaseService: BusinessCaseService,
    private readonly _assetListService: AssetListService,
    private readonly _loader: NgxUiLoaderService,
    private readonly _toastr: ToastrService,
    private readonly _router: Router,
    private readonly _overlayService: OverlayService
  ) {}

  public async ngOnInit(): Promise<void> {
    try {
      const routeParams: ParamMap = await firstValueFrom(this._route.paramMap);

      this.businessCaseId = routeParams.get('id') as unknown as number;
      this.currentStageName = (
        await firstValueFrom(this._route.queryParamMap)
      ).get('stage') as string;

      this.businessCase = (await firstValueFrom(
        this._businessCaseService.fetchBusinessCaseById(this.businessCaseId)
      )) as IBusinessCaseDetails;

      this.verifiedAssets = (
        await this._assetListService.getVerifiedAssets(this.businessCaseId)
      ).assetRetrievalList as unknown as Array<IBusinessCaseAsset>;

      this.businessCaseStagesConfigs = await firstValueFrom(
        this._businessCaseService.fetchBusinessCaseStagesConfigs(
          this.businessCaseId
        )
      );

      this.isEditor = this._businessCaseService.isEditor(
        this.businessCaseStagesConfigs,
        this.businessCase,
        this.currentStageName
      );

      this.isReviewer = this._businessCaseService.isReviewer(
        this.businessCaseStagesConfigs,
        this.businessCase,
        this.currentStageName
      );

      if (this.verifiedAssets.length > 0) {
        this.runRFQModeCheck();
      }
    } catch (error) {
      this._toastr.error('There waas an error while fetching the asset list');
    }
  }

  public assetsAreMissingPrices(): boolean {
    const isMissingAssetPrice = !this.verifiedAssets.every(
      (asset) =>
        asset.monthlyFee > 0 &&
        asset.reactiveFeeWithWarranty > 0 &&
        asset.reactiveFeeWithoutWarranty > 0
    );

    return isMissingAssetPrice;
  }

  public async runRFQModeCheck(): Promise<void> {
    this.assetsForPricing = (
      await this._assetListService.getAssetsForPricing(this.businessCaseId)
    ).assetRetrievalList;

    if (this.assetsAreMissingPrices() || this.assetsForPricing.length > 0) {
      this.isRFQMode = true;
    }
  }

  public async searchAssets(): Promise<void> {
    this._loader.start();
    try {
      this.verifiedAssets = (
        await this._assetListService.getVerifiedAssets(this.businessCaseId)
      ).assetRetrievalList as unknown as Array<IBusinessCaseAsset>;
      this._loader.stop();
    } catch (error) {
      this._loader.stop();
      this._toastr.error('There was an error while fetching the asset list');
    }
  }

  public async filterAssets(event: MatSelectChange): Promise<void> {
    this._loader.start();
    try {
      this.verifiedAssets = (
        await this._assetListService.getVerifiedAssets(this.businessCaseId)
      ).assetRetrievalList as unknown as Array<IBusinessCaseAsset>;
      this._loader.stop();
    } catch (error) {
      this._loader.stop();
      this._toastr.error('There was an error while fetching the asset list');
    }
  }

  public async submitRFQ(): Promise<void> {
    this._loader.start();

    const payload: BusinessCaseReviewPayload = {
      businessCaseId: this.businessCaseId,
      businessCaseStageId: this.businessCase?.activeStageId as number,
      businessCaseStageReviewStatus: BusinessCaseReviewStatus.APPROVED,
      rejectedReason: '',
      moreDetails: ''
    };

    try {
      this._loader.start();

      await firstValueFrom(
        this._businessCaseService.approveBusinessCase(payload)
      );

      this._loader.stop();
      this._toastr.success(`Request for quote sent successfully.`);

      await this._router.navigateByUrl(
        `${this.routes['BUSINESS_CASES']}/${this.businessCaseId}`
      );
    } catch (error) {
      this._loader.stop();
      this._toastr.error(
        `There was an issue with approving business case ${payload.businessCaseId}`
      );
    }
  }
  public async rejectAssetPricing(): Promise<void> {
    const dialogRef: MatDialogRef<ReasonForRejectionOverlayComponent> =
      this._dialog.open(ReasonForRejectionOverlayComponent, {
        width: '600px',
        height: '450px'
      });

    dialogRef
      .beforeClosed()
      .subscribe(async (data: { reason: string; details: string }) => {
        let payload: BusinessCaseReviewPayload;

        if (this.businessCase?.activeSecondStageName) {
          payload = {
            businessCaseId: this.businessCaseId as number,
            businessCaseStageId: this.businessCase?.activeSecondStageId as number,
            businessCaseStageReviewStatus: BusinessCaseReviewStatus.REJECTED,
            rejectedReason: data.reason,
            moreDetails: data.details
          };
        } else {
          payload = {
            businessCaseId: this.businessCaseId as number,
            businessCaseStageId: this.businessCase?.activeStageId as number,
            businessCaseStageReviewStatus: BusinessCaseReviewStatus.REJECTED,
            rejectedReason: data.reason,
            moreDetails: data.details
          };
        }

        try {
          this._loader.start();

          await firstValueFrom(
            this._businessCaseService.rejectBusinessCase(payload)
          );

          this._loader.stop();
          this._toastr.success(
            `Business case ${payload.businessCaseId} has been rejected successfully!`
          );

          this._router.navigateByUrl(this.routes['BUSINESS_CASES']);
        } catch (error) {
          this._toastr.error(
            `There was as issue rejecting the business case with id ${payload.businessCaseId}`
          );
        }

        this._loader.stop();
        await this._router.navigateByUrl(this.routes['BUSINESS_CASES']);
      });
  }
  public async submitQuotesForCostVerification(): Promise<void> {
    this._loader.start();
    let payload: BusinessCaseReviewPayload;
    try {
      this._loader.start();

      if (this.businessCase?.activeSecondStageName) {
        payload = {
          businessCaseId: this.businessCaseId as number,
          businessCaseStageId: this.businessCase?.activeSecondStageId as number,
          businessCaseStageReviewStatus: BusinessCaseReviewStatus.APPROVED,
          rejectedReason: '',
          moreDetails: ''
        };
      } else {
        payload = {
          businessCaseId: this.businessCaseId as number,
          businessCaseStageId: this.businessCase?.activeStageId as number,
          businessCaseStageReviewStatus: BusinessCaseReviewStatus.APPROVED,
          rejectedReason: '',
          moreDetails: ''
        };
      }

      await firstValueFrom(
        this._businessCaseService.approveBusinessCase(payload)
      );
      this._loader.stop();
      this._toastr.success(`Approved asset price gathering.`);

      await this._router.navigateByUrl(
        `${this.routes['BUSINESS_CASES']}/${this.businessCaseId}`
      );
    } catch (error) {
      this._loader.stop();
      this._toastr.error(
        `There was as issue approving the change note with id ${this.businessCaseId}`
      );
    }
  }

  public handleAssetData(data: IProcurementPriceList[]) {
    this.assetListData = data;
  }

  public async submitQuotesForCostVerificationForReview(): Promise<void> {
    this.handleAssetData(this.assetListData);
    this._loader.start();

    try {
      this._loader.start();
      if (this.businessCase?.activeSecondStageName) {
        await firstValueFrom(
          this._businessCaseService.submitBusinessCaseForReview({
            businessCaseId: this.businessCaseId as number,
            businessCaseStageId: this.businessCase
              ?.activeSecondStageId as number
          })
        );
      } else {
        await firstValueFrom(
          this._businessCaseService.submitBusinessCaseForReview({
            businessCaseId: this.businessCaseId as number,
            businessCaseStageId: this.businessCase?.activeStageId as number
          })
        );
      }

      this._loader.stop();
      this._toastr.success(`Request for quote sent successfully.`);

      await this._router.navigateByUrl(
        `${this.routes['BUSINESS_CASES']}/${this.businessCaseId}`
      );
    } catch (error) {
      this._loader.stop();
      this._toastr.error('An error occurred');
    }
  }

  public showActivityLogDialog(): void {
    this._overlayService.open(BusinessCaseActivityLogComponent, {
      key: 'businessCaseId',
      value: this.businessCase?.id as number
    });
  }
}
