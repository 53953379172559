import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  inject
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { IEquipmentRetrievalModel } from '../../../models/asset-list-equipment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AssetListService } from '../../../services/asset-list.service';

@Component({
  selector: 'bidvest-asset-list-unseen-overlay',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule
  ],
  templateUrl: './asset-list-unseen-overlay.component.html',
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' }
    }
  ],
  styleUrl: './asset-list-unseen-overlay.component.scss'
})
export class AssetListUnseenOverlayComponent {
  public form!: FormGroup;
  public constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data = inject<{
      data: IEquipmentRetrievalModel;
      businessCaseId: number;
    }>(MAT_DIALOG_DATA),
    private readonly _fb: FormBuilder,
    private readonly _toast: ToastrService,
    private readonly _loader: NgxUiLoaderService,
    private _assetService: AssetListService,
    private readonly _dialogRef: MatDialogRef<AssetListUnseenOverlayComponent>
  ) {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.form = this._fb.group({
      equipmentId: [this.data.data.equipmentId, [Validators.required]],
      discipline: [this.data.data.discipline, [Validators.required]],
      description: [this.data.data.equipmentText, [Validators.required]],
      note: ['', [Validators.required]]
    });

    const equipmentIdControl = this.form.get('equipmentId');
    const disciplineControl = this.form.get('discipline');
    const descriptionControl = this.form.get('description');

    equipmentIdControl?.disable();
    disciplineControl?.disable();
    descriptionControl?.disable();
  }

  public async rejectAsset() {
    try {
      this._loader.start();
      const payload = {
        assetNotSeen: {
          note: this.form.value.note
        },
        equipmentText: this.form.value.description,
        seen: false,
        equipmentNumber: this.data.data.equipmentId,
        siteNumber: this.data.data.siteId,
        mandate: this.data.data.mandate,
        monthlyFee: this.data.data.monthlyFee,
        templateNumber: this.data.data.templateNumber,
        strategy: this.data.data.strategy,
        implementationDate: this.data.data.implementationDate,
        refreshDate: this.data.data.refreshDate,
        reactiveFeeWithWarranty: this.data.data.reactiveFeeWithWarranty,
        reactiveFeeWithoutWarranty: this.data.data.reactiveFeeWithoutWarranty,
        discipline: this.data.data.discipline
      };

      await this._assetService.assetVerification(
        payload,
        this.data.businessCaseId
      );
      this._toast.success('Asset has been marked as unseen');
      this._loader.stop();
      this._dialogRef.close();
    } catch {
      this._toast.error(
        'Something went wrong. Please contact your administrator',
        'Error'
      );
      this._loader.stop();
    }
  }

  public closeAssetList() {
    this._dialogRef.close();
  }
}
