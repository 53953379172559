<form [formGroup]="businessCaseForm" class="flex flex-col w-full">
  <section class="grid grid-cols-12 gap-4 w-full mb-4">
    <div class="col-span-12 rounded bg-slate-100 p-4">
      <h4 class="font-semibold">Details</h4>
    </div>

    <div class="col-span-12 flex flex-col mb-4 pt-8">
      <mat-form-field appearance="outline" floatLabel="always" class="w-full">
        <mat-label matTooltip="Info about the action"
          >Select Business Case Action Type</mat-label
        >
        <mat-select formControlName="businessCaseActionTypes" multiple>
          @for (type of businessCaseActionTypes; track type) {
          <mat-option [value]="type">{{
            type.trim().split('_').join(' ')
          }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-span-12">
      <mat-divider></mat-divider>
    </div>

    <mat-form-field
      appearance="outline"
      floatLabel="always"
      class="col-span-12 mt-8 mb-4"
    >
      <mat-label>Business case name</mat-label>
      <input type="text" matInput formControlName="name" />
    </mat-form-field>

<!--    <mat-form-field-->
<!--      appearance="outline"-->
<!--      floatLabel="always"-->
<!--      class="col-span-12"-->
<!--    >-->
<!--      <mat-label>Business case description</mat-label>-->
<!--      <textarea matInput rows="5" formControlName="description"></textarea>-->
<!--    </mat-form-field>-->

    <mat-form-field
      appearance="outline"
      floatLabel="always"
      class="col-span-12"
    >
      <mat-label>Implementation Date</mat-label>

      <input
        matInput
        [matDatepicker]="picker"
        formControlName="implementationDate"
      />

      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      floatLabel="always"
      class="col-span-12 mb-4"
    >
      <mat-label>Building number</mat-label>
      <input type="text" matInput formControlName="buildingNumber" />
      <button
        mat-icon-button
        matSuffix
        [attr.aria-label]="'Show building number dialog'"
        (click)="showBuildingNumberInputDialog($event)"
      >
        <mat-icon>add</mat-icon>
      </button>

      @if (businessCaseForm.get('buildingNumber')?.dirty &&
      this.businessCaseForm.get('buildingNumber')?.invalid) {
      <mat-error>Building number not found!</mat-error>
      }
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always" class="col-span-6">
      <mat-label>Town</mat-label>
      <input type="text" matInput formControlName="town" />
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always" class="col-span-6">
      <mat-label>Province</mat-label>
      <mat-select formControlName="province"(selectionChange)="provinceChanged($event.value)">
        @for (province of provinces; track $index) {
        <mat-option [value]="province">{{ province }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always" class="col-span-6">
      <mat-label>Outlet</mat-label>
      <input type="text" matInput formControlName="outlet" />
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always" class="col-span-6">
      <mat-label>Outlet Type</mat-label>
      <mat-select formControlName="outletType">
        @for (outletType of outletTypes; track $index) {
        <mat-option [value]="outletType">{{ outletType }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </section>

  <mat-divider></mat-divider>

  <section class="flex flex-col w-full my-4">
    <div class="rounded w-full bg-slate-100 p-4">
      <h4 class="font-semibold">Uploads</h4>
    </div>

    <div class="flex flex-row justify-start py-4">
      <div class="flex flex-col mr-4">
        <bidvest-file-upload
          (uploadSuccess)="requiredDocs.cdaLayout = true"
          [allowedMimeTypes]="['.pdf']"
          [businessCaseId]="businessCaseId"
          [url]="'documents/CONSTRUCTION_DRAWING'"
          [required]="true"
        ></bidvest-file-upload>
        <small class="font-semibold mt-4">CONSTRUCTION DRAWING</small>
      </div>
      <div class="flex flex-col mr-4">
        <bidvest-file-upload
          (uploadSuccess)="requiredDocs.iopSchedule = true"
          [allowedMimeTypes]="['.pdf']"
          [businessCaseId]="businessCaseId"
          [url]="'documents/IOP_SCHEDULE'"
          [required]="true"
        ></bidvest-file-upload>
        <small class="font-semibold mt-4">IOP SCHEDULE</small>
      </div>
      <div class="flex flex-col mr-4">
        <bidvest-file-upload
          (uploadSuccess)="requiredDocs.rr03PropertyList = true"
          [allowedMimeTypes]="['.pdf']"
          [businessCaseId]="businessCaseId"
          [url]="'documents/RR03_PROPERTY_LIST'"
          [required]="true"
        ></bidvest-file-upload>
        <small class="font-semibold mt-4">RR03 PROPERTY LIST</small>
      </div>
    </div>
  </section>

  <mat-divider></mat-divider>

  <section class="flex flex-col w-full mt-4" formGroupName="collaborators">
    <div class="rounded w-full bg-slate-100 p-4">
      <h4 class="font-semibold">Collaborators</h4>
    </div>

    <div class="w-full flex flex-col mb-4">
      <span class="my-4">
        <h4 class="text-lg">Stages</h4>
      </span>
    </div>

    @for (stageData of initialStagesCollaboratorsData; track $index) {

    <bidvest-collaborators-selector
      [businessCaseAccountsPerProvince]="usersPerProvince"
      [businessCaseAccounts]="collaborators"
      [collaboratorsData]="stageData"
      (collaboratorsSelection)="getCollaboratorSelection($event)"
    ></bidvest-collaborators-selector>

    <mat-divider></mat-divider>
    }
  </section>
</form>
