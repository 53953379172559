<div class="border-2 border-solid overflow-x-auto rounded-t-lg w-full flex">
  <table mat-table [dataSource]="dataSource" appearance="outline">
    @if (currentStageName() === businessCaseStages.PROCUREMENT_TO_PRICE) {
    <ng-container matColumnDef="discipline">
      <th mat-header-cell *matHeaderCellDef>Discipline</th>
      <td mat-cell *matCellDef="let asset">{{ asset.discipline }}</td>
    </ng-container>

    <ng-container matColumnDef="equipmentId">
      <th mat-header-cell *matHeaderCellDef>Equipment ID</th>
      <td mat-cell *matCellDef="let asset">{{ asset.equipmentId }}</td>
    </ng-container>

    <ng-container matColumnDef="buildingId">
      <th mat-header-cell *matHeaderCellDef>Building ID</th>
      <td mat-cell *matCellDef="let asset">{{ asset.siteId }}</td>
    </ng-container>

    <ng-container matColumnDef="monthlyFee">
      <th mat-header-cell *matHeaderCellDef>Monthly Fee</th>
      <td mat-cell *matCellDef="let asset">
        <ng-container *ngIf="isEditor()">
          <mat-form-field appearance="outline" floatLabel="always" class="mt-4">
            <span class="font-bold text-primary-blue px-1">R</span>
            <input
              type="text"
              matInput
              name="monthlyFee"
              [(ngModel)]="asset.monthlyFee"
              (ngModelChange)="submitPriceArray()"
            />
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="isReviewer()">
          <span class="font-bold text-primary-blue">R</span>
          {{ asset.monthlyFee | number: '1.2' }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="monthlyReactiveFeeWithWarranty">
      <th mat-header-cell *matHeaderCellDef>Reactive fee (warranty)</th>
      <td mat-cell *matCellDef="let asset">
        <ng-container *ngIf="isEditor()">
          <mat-form-field appearance="outline" floatLabel="always" class="mt-4">
            <span class="font-bold text-primary-blue px-1">R</span>
            <input
              type="text"
              matInput
              name="monthlyReactiveFeeWithWarranty"
              [(ngModel)]="asset.reactiveFeeWithWarranty"
              (ngModelChange)="submitPriceArray()"
            />
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="isReviewer()">
          <span class="font-bold text-primary-blue">R</span>
          {{ asset.reactiveFeeWithWarranty | number: '1.2' }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="monthlyReactiveFeeWithoutWarranty">
      <th mat-header-cell *matHeaderCellDef>Reactive fee (w/o warranty)</th>
      <td mat-cell *matCellDef="let asset">
        <ng-container *ngIf="isEditor()">
          <mat-form-field appearance="outline" floatLabel="always" class="mt-4">
            <span class="font-bold text-primary-blue px-1">R</span>
            <input
              type="text"
              matInput
              name="monthlyReactiveFeeWithoutWarranty"
              [(ngModel)]="asset.reactiveFeeWithoutWarranty"
              (ngModelChange)="submitPriceArray()"
            />
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="isReviewer()">
          <span class="font-bold text-primary-blue">R</span>
          {{ asset.reactiveFeeWithoutWarranty | number: '1.2' }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="strategy">
      <th mat-header-cell *matHeaderCellDef>Strategy</th>
      <td mat-cell class="beforeCell" *matCellDef="let asset">
        {{ asset.strategy }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Asset status</th>
      <td mat-cell class="beforeCell" *matCellDef="let asset">
        @if (asset?.assetSeen?.assetSeenStatus === 'REPLACE') {
        <span
          class="bg-[#B8B800] px-4 py-2 text-white font-semibold text-xs rounded-full"
          *ngIf="asset.seen && asset.assetSeen !== null"
        >
          {{ transformToSentenceCase(asset?.assetSeen?.assetSeenStatus) }}
        </span>
        } @else if (asset?.assetSeen?.assetSeenStatus === 'REMAIN') {
        <span
          class="bg-bidvest-green px-4 py-2 text-white font-semibold text-xs rounded-full"
          *ngIf="asset.seen && asset.assetSeen !== null"
        >
          {{ transformToSentenceCase(asset?.assetSeen?.assetSeenStatus) }}
        </span>
        } @else if (asset?.assetSeen?.assetSeenStatus === 'NEW_FIRST_TIME_INSTALLATION') {
        <span
          class="bg-yellow-400 px-4 py-2 text-white font-semibold text-xs rounded-full"
          *ngIf="asset.seen && asset.assetSeen !== null"
        >
          {{ transformToSentenceCase(asset?.assetSeen?.assetSeenStatus) }}
        </span>
        } @else if (asset?.assetSeen?.assetSeenStatus === 'REMOVED') {
        <span
          class="bg-red-400 px-4 py-2 text-white font-semibold text-xs rounded-full"
          *ngIf="asset.seen && asset.assetSeen !== null"
        >
          {{ transformToSentenceCase(asset?.assetSeen?.assetSeenStatus) }}
        </span>
        }
        <span
          class="bg-red-600 px-4 py-2 text-white font-semibold text-xs rounded-full"
          *ngIf="!asset.seen && asset.assetNotSeen !== null"
        >
          Asset not seen
        </span>
        <span
          class="bg-gray-600 px-4 py-2 text-white font-semibold text-xs rounded-full"
          *ngIf="asset.assetNotSeen === null && asset.assetSeen === null"
        >
          Asset not verified
        </span>
      </td>
    </ng-container>
    } @else {
    <ng-container matColumnDef="discipline">
      <th mat-header-cell *matHeaderCellDef>Discipline</th>
      <td mat-cell *matCellDef="let asset">{{ asset.discipline }}</td>
    </ng-container>

    <ng-container matColumnDef="equipmentId">
      <th mat-header-cell *matHeaderCellDef>Equipment ID</th>
      <td mat-cell *matCellDef="let asset">{{ asset.equipmentId }}</td>
    </ng-container>

    <ng-container matColumnDef="buildingId">
      <th mat-header-cell *matHeaderCellDef>Building ID</th>
      <td mat-cell *matCellDef="let asset">{{ asset.siteId }}</td>
    </ng-container>

    <ng-container matColumnDef="highLevelDescription">
      <th mat-header-cell *matHeaderCellDef>Equipment Text</th>
      <td mat-cell *matCellDef="let asset">{{ asset.equipmentText }}</td>
    </ng-container>

    <ng-container matColumnDef="monthlyFee">
      <th mat-header-cell *matHeaderCellDef>Monthly Fee</th>
      <td mat-cell *matCellDef="let asset">
        <span class="font-bold text-primary-blue">R</span>
        {{ asset.monthlyFee | number: '1.2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="monthlyReactiveFeeWithWarranty">
      <th mat-header-cell *matHeaderCellDef>Reactive fee (warranty)</th>
      <td mat-cell *matCellDef="let asset">
        <span class="font-bold text-primary-blue">R</span>
        {{ asset.reactiveFeeWithWarranty | number: '1.2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="monthlyReactiveFeeWithoutWarranty">
      <th mat-header-cell *matHeaderCellDef>Reactive fee (w/o warranty)</th>
      <td mat-cell *matCellDef="let asset">
        <span class="font-bold text-primary-blue">R</span>
        {{ asset.reactiveFeeWithoutWarranty | number: '1.2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="strategy">
      <th mat-header-cell *matHeaderCellDef>Strategy</th>
      <td mat-cell class="beforeCell" *matCellDef="let asset">
        {{ asset.strategy }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Asset status</th>
      <td mat-cell class="beforeCell" *matCellDef="let asset">
        @if (asset?.assetSeen?.assetSeenStatus === 'REPLACE') {
        <span
          class="bg-[#B8B800] px-4 py-2 text-white font-semibold text-xs rounded-full"
        >
          {{ transformToSentenceCase(asset?.assetSeen?.assetSeenStatus) }}
        </span>
        } @else if (asset?.assetSeen?.assetSeenStatus === 'REMAIN') {
        <span
          class="bg-bidvest-green px-4 py-2 text-white font-semibold text-xs rounded-full"
        >
          {{ transformToSentenceCase(asset?.assetSeen?.assetSeenStatus) }}
        </span>
        } @else if (asset?.assetSeen?.assetSeenStatus === 'NEW_FIRST_TIME_INSTALLATION') {
        <span
          class="bg-yellow-400 px-4 py-2 text-white font-semibold text-xs rounded-full"
        >
          {{ transformToSentenceCase(asset?.assetSeen?.assetSeenStatus) }}
        </span>
        } @else if (asset?.assetSeen?.assetSeenStatus === 'REMOVED') {
        <span
          class="bg-bidvest-red px-4 py-2 text-white font-semibold text-xs rounded-full"
        >
          {{ transformToSentenceCase(asset?.assetSeen?.assetSeenStatus) }}
        </span>
        }
        <span
          class="bg-red-500 px-4 py-2 text-white font-semibold text-xs rounded-full"
          *ngIf="!asset.seen && asset.assetNotSeen !== null"
        >
          Asset not seen
        </span>
        <span
          class="bg-gray-500 px-4 py-2 text-white font-semibold text-xs rounded-full"
          *ngIf="asset.assetNotSeen === null && asset.assetSeen === null"
        >
          Asset not verified
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell class="beforeCell" *matCellDef="let asset">
        <ng-container *ngIf="isEditor()">
          <span>
            <button
              *ngIf="
                asset?.monthlyFee === 0 ||
                asset?.reactiveFeeWithWarranty === 0 ||
                asset?.reactiveFeeWithoutWarranty === 0
              "
              mat-icon-button
              (click)="openUpdateAssetPriceDialog(asset)"
            >
              <mat-icon>edit_square</mat-icon>
            </button>
            <button
              *ngIf="asset?.assetSeen?.assetSeenStatus === 'REPLACE'"
              mat-icon-button
              (click)="openAssetReplacementDialog(asset)"
            >
              <mat-icon>edit_square</mat-icon>
            </button>
          </span>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="declinedReasons">
      <th mat-header-cell *matHeaderCellDef>
        {{ isEditor() ? 'Reason for decline' : '' }}
      </th>
      <td mat-cell class="beforeCell" *matCellDef="let asset">
        <span *ngIf="isEditor()">
          {{
            asset.assetDecline === null ? '' : asset.assetDecline.declineReason
          }}
        </span>
      </td>
    </ng-container>
    }

    <tr
      mat-header-row
      *matHeaderRowDef="
        currentStageName() === businessCaseStages.PROCUREMENT_TO_PRICE
          ? procurementOnlyColumns
          : displayedColumns
      "
    ></tr>
    <tr
      mat-row
      *matRowDef="
        let row;
        columns: currentStageName() === businessCaseStages.PROCUREMENT_TO_PRICE
          ? procurementOnlyColumns
          : displayedColumns
      "
    ></tr>

    <tr class="w-full" *matNoDataRow>
      <td [colSpan]="displayedColumns.length">
        <div class="h-96 flex flex-row justify-center items-center font-bold">
          No Data
        </div>
      </td>
    </tr>
  </table>
</div>
<div class="border-2 border-solid border-t-0">
  <mat-paginator aria-label="Select page"></mat-paginator>
</div>
