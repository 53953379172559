import {
  Component,
  input,
  InputSignal,
  OnDestroy,
  OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  backOfficeRouteKeys,
  BusinessCaseHeaderComponent,
  FileUploadComponent,
  Provinces
} from '@bidvest/shared';
import { MatIconModule } from '@angular/material/icon';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { firstValueFrom, Subject } from 'rxjs';
import {
  Collaborator,
  UpdateBusinessCasePayload
} from '../../interfaces/update-business-case-payload.interface';
import { BusinessCaseService } from '../../services/business-case.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BusinessCaseStage } from '../../interfaces/business-case-setup.interface';
import {
  IBusinessCaseDetails,
  IDocument
} from '../../interfaces/business-case-details.interface';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { IBusinessCaseAccount } from '../../interfaces/collaborator.interface';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IStageCollaborationInput } from '../../interfaces/collaborator-input.interface';
import { CollaboratorsSelectorComponent } from '../../components/collaborators-selector/collaborators-selector.component';
import { MatChipsModule } from '@angular/material/chips';
import { IBusinessCaseStageConfig } from '../../interfaces/business-case-stage-config.interface';
import { environment } from '@env/environment';
import { DelayNoticeDialogComponent } from '../../components/dialogs/delay-notice-dialog/delay-notice-dialog.component';
import { ReasonForDelayDialogComponent } from '../../components/dialogs/reason-for-delay-dialog/reason-for-delay-dialog.component';
import { FileSaverService } from 'ngx-filesaver';
import { IBusinessCaseDocument } from '../../interfaces/business-case-document.interface';
import { BusinessCaseDocumentTypes } from '../../enums/business-case-document-types.enum';
import { BuildingNumberInputDialogComponent } from '../../components/dialogs/building-number-input-dialog/building-number-input-dialog.component';
import { BuildingData } from '../../interfaces/building-data.interface';
import {DelayStatusPopupService} from "../../../../../../../shared/src/lib/shared/services/delay-status-popup.service";
import {BusinessCaseStageStatus} from "../../enums/business-case-stage-status.enum";

@Component({
  selector: 'bidvest-business-case-draft',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatDatepickerModule,
    FileUploadComponent,
    MatOptionModule,
    MatSelectModule,
    BusinessCaseHeaderComponent,
    RouterLink,
    CollaboratorsSelectorComponent,
    MatChipsModule
  ],
  templateUrl: './business-case-draft.component.html',
  styleUrl: './business-case-draft.component.scss',
  providers: [provideMomentDateAdapter()]
})
export class BusinessCaseDraftComponent implements OnInit, OnDestroy {
  public stageConfigs: InputSignal<
    Array<IBusinessCaseStageConfig> | undefined
  > = input();

  public businessCaseStages: Array<BusinessCaseStage> = [];
  public businessCaseDetails: IBusinessCaseDetails | undefined;
  public businessCaseAccounts: Array<IBusinessCaseAccount> = [];
  public businessCaseForm!: FormGroup;
  public selectedStageCollaborators: Array<IStageCollaborationInput> = [];
  public routes: { [key: string]: string } = backOfficeRouteKeys;
  public baseUrl: string = environment.apiUrl;
  public bcReviewers: Record<string, Array<string>> = {};
  public requiredDocs: {
    cdaLayout: boolean;
    iopSchedule: boolean;
    rr03PropertyList: boolean;
  } = {
    cdaLayout: false,
    iopSchedule: false,
    rr03PropertyList: false
  };
  public businessCaseActionTypes: Array<string> = [
    'TERMINATION',
    'DOWN_SELL',
    'UPSELL',
    'NEW_BUILDING'
  ];
  public provinces: Array<string> = Provinces;
  public outletTypes: Array<string> = ['Other', 'Retail', 'Corporate', 'ATM'];
  private readonly _destroy$: Subject<void> = new Subject<void>();
  public businessCaseStageStatus!: BusinessCaseStageStatus;

  public constructor(
    private readonly _businessCaseService: BusinessCaseService,
    private readonly _fb: FormBuilder,
    private readonly _toastr: ToastrService,
    private readonly _loader: NgxUiLoaderService,
    private readonly _route: ActivatedRoute,
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
    private readonly _delayStatusPopupService: DelayStatusPopupService,
    private readonly _fileSaverService: FileSaverService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.businessCaseForm = this._fb.group({
      businessCaseActionTypes: ['', Validators.required],
      name: ['', Validators.required],
      implementationDate: [new Date(), Validators.required],
      description: ['', Validators.required],
      town: ['', Validators.required],
      province: ['', Validators.required],
      buildingNumber: ['', Validators.required],
      outletType: ['', Validators.required],
      outlet: ['', Validators.required]
    });

    await this.getBusinessCaseAccounts();

    const params: Params = await firstValueFrom(this._route.paramMap);
    const businessCaseId = params['get']('id');

    const businessCase: IBusinessCaseDetails = await firstValueFrom(
      this._businessCaseService.fetchBusinessCaseById(businessCaseId)
    );

    this.businessCaseDetails = businessCase;
    this.businessCaseStages = businessCase.collaborators.map((stage) => ({
      id: stage.id,
      stageName: stage.businessCaseStageName
    }));

    this.setBCReviewers(businessCase);
    this._runBCDocumentCheck(businessCase);
    this.prepopulateForm(businessCase);
    const stageConfig: IBusinessCaseStageConfig[] | undefined = this.stageConfigs();
    await this._delayStatusPopupService.checkBCDelayStatus(stageConfig, this.businessCaseDetails);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public async getBusinessCaseAccounts(): Promise<void> {
    try {
      const data = await firstValueFrom(
        this._businessCaseService.fetchBusinessCaseAccounts()
      );
      this.businessCaseAccounts = [...data];
    } catch (error) {}
  }

  public prepopulateForm(data: IBusinessCaseDetails): void {
    this.businessCaseForm.patchValue({
      businessCaseActionTypes: data.businessCaseActionTypes,
      name: data.businessCaseName,
      implementationDate: new Date(data.implementationDate),
      description: data.businessCaseDescription,
      town: data.town,
      province: data.province,
      buildingNumber: data.buildingNumber,
      outletType: data.outletType,
      outlet: data.outlet
    });

    if (this.businessCaseDetails?.activeStageStatus === 'IN_REVIEW') {
      this.businessCaseForm.disable();
    } else {
      // Disable only building number input
      this.businessCaseForm.get('buildingNumber')?.disable({ onlySelf: true });
    }
  }

  async submitBusinessCaseForReview(): Promise<void> {
    if (
      this.businessCaseForm.valid &&
      this.areCollaboratorsValid() &&
      this.requiredDocs.cdaLayout &&
      this.requiredDocs.iopSchedule &&
      this.requiredDocs.rr03PropertyList
    ) {
      const {
        businessCaseActionTypes,
        name,
        implementationDate,
        description,
        town,
        province,
        buildingNumber,
        outletType,
        outlet
      } = this.businessCaseForm.getRawValue();

      this._loader.start();

      const payload: UpdateBusinessCasePayload = {
        id: this.businessCaseDetails?.id as number,
        referenceNumber: this.businessCaseDetails?.referenceNumber as string,
        businessCaseName: name,
        buildingNumber,
        implementationDate,
        businessCaseActionTypes,
        writeUp: '',
        town,
        province,
        outlet,
        outletType,
        collaborators: [...this.selectedStageCollaborators],
        businessCaseDescription: description
      };

      try {
        await firstValueFrom(
          this._businessCaseService.updateBusinessCase(payload)
        );

        await firstValueFrom(
          this._businessCaseService.submitBusinessCaseForReview({
            businessCaseId: this.businessCaseDetails?.id as number,
            businessCaseStageId: this.businessCaseDetails
              ?.activeStageId as number
          })
        );

        this._loader.stop();

        this._toastr.success(
          `Business Case ${this.businessCaseDetails?.referenceNumber} has been submitted for review`
        );
        this._businessCaseService.businessCaseTableFilter.next(
          this._businessCaseService.defaultFilter
        );
        this._router.navigateByUrl(this.routes['BUSINESS_CASES']);
      } catch (error) {
        this._toastr.error(
          `There was an error submitting business case ${this.businessCaseDetails?.referenceNumber} for review.`
        );
      } finally {
        this._loader.stop();
      }
    }
  }

  public getCollaboratorSelection(val: IStageCollaborationInput) {
    const stageSelections = this.selectedStageCollaborators.find(
      (data) => data.businessCaseStageName === val.businessCaseStageName
    );
    if (stageSelections) {
      stageSelections.ownerId = val.ownerId;
      stageSelections.reviewerIds = [...val.reviewerIds];
      stageSelections.id = val.id;
    } else {
      this.selectedStageCollaborators.push(val);
    }
  }

  public areCollaboratorsValid(): boolean {
    return this.selectedStageCollaborators.every(
      (val) =>
        val.ownerId !== null &&
        val.businessCaseStageName !== null &&
        val.reviewerIds.length > 0 &&
        val.id !== null
    );
  }

  public checkRequiredDocs(doc: IDocument): void {
    if (doc.documentType === BusinessCaseDocumentTypes.CONSTRUCTION_DRAWING) {
      this.requiredDocs.cdaLayout = true;
    } else if (doc.documentType === BusinessCaseDocumentTypes.IOP_SCHEDULE) {
      this.requiredDocs.iopSchedule = true;
    } else if (
      doc.documentType === BusinessCaseDocumentTypes.RR03_PROPERTY_LIST
    ) {
      this.requiredDocs.rr03PropertyList = true;
    }
  }

  public getStageOwner(stage: BusinessCaseStage): string {
    const stageOwnerName = this.stageConfigs()?.find(
      (config) => config.stageId === stage.id
    )?.stageOwner;

    return stageOwnerName as string;
  }

  public isReadOnly(): boolean {
    const activeStage: IBusinessCaseStageConfig | undefined =
      this.stageConfigs()?.find(
        (stage) => stage.stageName === this.businessCaseDetails?.activeStageName
      );

    if (activeStage && !activeStage.canEdit) {
      return true;
    } else {
      return false;
    }
  }

  public setBCReviewers(bcDetails: IBusinessCaseDetails): void {
    const bcCollaborators = bcDetails.collaborators as Array<Collaborator>;

    const bcStageNames = bcCollaborators.map((c) => c.businessCaseStageName);

    for (let name of bcStageNames) {
      this.bcReviewers[name] = [];
    }

    for (let name of bcStageNames) {
      const stageReviewerIds: Array<number> = bcCollaborators.find(
        (c) => c.businessCaseStageName === name
      )?.reviewerIds as Array<number>;

      for (let id of stageReviewerIds) {
        const account = this.businessCaseAccounts.find(
          (account) => account.id === id
        ) as IBusinessCaseAccount;

        this.bcReviewers[name].push(account.fullName);
      }
    }
  }

  public downloadDocument(doc: IBusinessCaseDocument): void {
    const url = `business-cases/${this.businessCaseDetails?.id}/documents/${doc.id}/${this.businessCaseDetails?.referenceNumber}/${doc.documentType}/${doc.guid}/${doc.documentSystemName}`;
    this._businessCaseService.downloadBCDocument(url).subscribe({
      next: (val) => {
        this._fileSaverService.save(val.body, `${doc.documentName}`);
      }
    });
  }

  public showBuildingNumberInputDialog(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const dialogRef = this._dialog.open(BuildingNumberInputDialogComponent, {
      width: '600px',
      height: '200px'
    });

    dialogRef.beforeClosed().subscribe({
      next: (data: BuildingData) => {
        if (data) {
          this.businessCaseForm.patchValue({
            buildingNumber: data.ONEVIEW,
            outlet: data.PLTXT
          });
        } else {
          this.businessCaseForm.get('buildingNumber')?.markAsDirty();
          this.businessCaseForm.get('buildingNumber')?.markAsTouched();
        }
      }
    });
  }

  private _runBCDocumentCheck(data: IBusinessCaseDetails): void {
    data.documents.forEach((doc) => {
      this.checkRequiredDocs(doc);
    });
  }

  protected readonly BusinessCaseStageStatus = BusinessCaseStageStatus;
}
