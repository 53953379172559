import { CommonModule, NgFor, NgIf } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Inject
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef
} from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { IBusinessCaseAsset } from '../../../interfaces/business-case-asset.interface';
import { AssetListService } from '../../../services/asset-list.service';
import { AutocompleteInputComponent } from '@bidvest/shared';
import { IEquipmentRetrievalModel } from '../../../models/asset-list-equipment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { OverlayModule } from '@angular/cdk/overlay';

@Component({
  selector: 'bidvest-asset-replacing-overlay',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatDividerModule,
    MatDialogClose,
    MatDividerModule,
    MatDialogContent,
    MatDialogActions,
    MatProgressSpinnerModule,
    MatListModule,
    NgFor,
    OverlayModule
  ],
  templateUrl: './asset-replacing-overlay.component.html',
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' }
    }
  ],
  styleUrl: './asset-replacing-overlay.component.scss'
})
export class AssetReplaceOverlayComponent implements OnInit {
  public form!: FormGroup;
  public bcEquipments: Array<IEquipmentRetrievalModel> = [];
  public selectedEquipment!: IEquipmentRetrievalModel;
  public showQueryResults: boolean = false;

  public constructor(
    private readonly _fb: FormBuilder,
    private readonly _loader: NgxUiLoaderService,
    private readonly _toast: ToastrService,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: { asset: IBusinessCaseAsset; businessCaseId: number },
    private readonly _assetListService: AssetListService,
    private readonly _toastr: ToastrService,
    private readonly _dialogRef: MatDialogRef<AssetReplaceOverlayComponent>
  ) {}

  public ngOnInit(): void {
    this.initializeForm();
  }

  public addNewRateCard() {
    this._loader.start();
    if (this.form['status'] === 'VALID') {
      this._toast.success('New rate card has been added successfully');
      this._loader.stop();
    } else {
      this._toast.error(
        'An error has occurred while trying to update the rate card.'
      );
      this._loader.stop();
    }
  }

  private initializeForm(): void {
    this.form = this._fb.group({
      currentEquipmentId: [this.data.asset.equipmentId],
      currentEquipmentDiscipline: [this.data.asset.discipline],
      currentEquipmentHighLevelDescription: [this.data.asset.equipmentText],
      equipmentId: ['', [Validators.required]],
      discipline: ['', [Validators.required]],
      highLevelDescription: ['', [Validators.required]]
    });

    const currentEquipmentIdControl = this.form.get('currentEquipmentId');
    const currentEquipmentDisciplineControl = this.form.get(
      'currentEquipmentDiscipline'
    );
    const currentEquipmentHighLevelDescriptionControl = this.form.get(
      'currentEquipmentHighLevelDescription'
    );
    const equipmentIdControl = this.form.get('equipmentId');
    const disciplineControl = this.form.get('discipline');
    const highLevelDescriptionControl = this.form.get('highLevelDescription');

    currentEquipmentIdControl?.disable();
    currentEquipmentDisciplineControl?.disable();
    currentEquipmentHighLevelDescriptionControl?.disable();

    disciplineControl?.disable();
    highLevelDescriptionControl?.disable();
  }

  public async submitAssetReplacementForm(): Promise<void> {
    if (this.form.valid) {
      try {
        const payload = this.data.asset;
        const formEntries = this.form.getRawValue();

        const id = this.data.businessCaseId;
        await this._assetListService.replaceAsset(
          id,
          payload,
          formEntries.equipmentId as string
        );

        this._dialogRef.close();

        this._toastr.success('Asset replaced successfully');
      } catch (error) {}
    }
  }

  public async fetchEquipmentById(): Promise<void> {
    const equipmentId = this.form.get('equipmentId')?.value as string;
    this._loader.start();

    try {
      this.bcEquipments = [
        ...(await this._assetListService.fetchEquipmentById(
          this.data.businessCaseId as number,
          equipmentId
        ))
      ];
      this.showQueryResults = true;

      this._loader.stop();
    } catch (error) {
      this._loader.stop();
    }
  }

  public onEquipmentSelect(equipment: IEquipmentRetrievalModel): void {
    this.form.patchValue({
      equipmentId: equipment.equipmentId,
      discipline: equipment.discipline,
      highLevelDescription: equipment.equipmentText
    });

    this.selectedEquipment = equipment;
    this.toggleDropDown();
  }

  public resetControl(): void {
    this.form.get('equipmentId')?.reset();
    this.bcEquipments = [];
  }

  public toggleDropDown(open?: boolean): void {
    this.showQueryResults = !this.showQueryResults;
  }
}
