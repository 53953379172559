import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogRef
} from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDividerModule } from '@angular/material/divider';
import { IUploadState } from '@bidvest/shared';
import { ToastrService } from 'ngx-toastr';
import { format } from 'date-fns';
import { BusinessCaseService } from '../../../services/business-case.service';
import {BusinessCaseStagesEnum} from "../../../enums/business-case-stages.enum";
import {
  TransformEnumToSentenceCasePipe
} from "../../../../../../../../shared/src/lib/shared/pipes/transform-enum-to-sentence-case.pipe";

@Component({
  selector: 'bidvest-reason-for-delay-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogContent,
    MatSelectModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatDividerModule,
    TransformEnumToSentenceCasePipe
  ],
  templateUrl: './reason-for-delay-dialog.component.html',
  styleUrl: './reason-for-delay-dialog.component.scss',
  providers: [provideMomentDateAdapter()]
})
export class ReasonForDelayDialogComponent implements OnInit {
  public reasonsForDelay: Array<string> = [
    'Incomplete Information',
    'Budgetary Concerns',
    'Insufficient Supporting Data',
    'Inaccurate Data',
    'Timing Conflicts',
    'Stakeholder Issues'
  ];
  public reasonForDelayForm!: FormGroup;
  public data = inject<{
    slaId: number;
    stageName: BusinessCaseStagesEnum;
  }>(MAT_DIALOG_DATA);
  public uploadState: IUploadState = {
    currentFile: null,
    fileName: 'Select File',
    progress: 0
  };
  public allowedMimeTypes: Array<string> = [
    '.pdf',
    '.png',
    '.docx',
    '.jpg',
    '.jpeg'
  ];

  private readonly _fb: FormBuilder = inject(FormBuilder);
  private readonly _toastr: ToastrService = inject(ToastrService);
  private readonly _bcService: BusinessCaseService =
    inject(BusinessCaseService);
  private readonly _dialogRef: MatDialogRef<ReasonForDelayDialogComponent> =
    inject(MatDialogRef);

  ngOnInit(): void {
    this.reasonForDelayForm = this._fb.group({
      reasonForDelay: ['', Validators.required],
      moreDetails: [''],
      implementationDate: [new Date(), Validators.required],
      file: ['']
    });
  }

  public showDocUploadSuccess(): void {
    this._toastr.success(`Document uploaded successfully!`);
  }

  public onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadState.currentFile = file;
      this.uploadState.fileName = this.uploadState.currentFile?.name as string;
      this.reasonForDelayForm.get('file')?.setValue(file);
    }
  }

  public dragAndDropFile(files: any): void {
    if (files && files[0]) {
      const file: File = files[0];
      this.uploadState.currentFile = file;
      this.uploadState.fileName = this.uploadState.currentFile.name;
      this.reasonForDelayForm.get('file')?.setValue(file);
    }
  }

  public submitForm(): void {
    if (this.reasonForDelayForm.valid) {
      const { reasonForDelay, moreDetails, implementationDate, file } =
        this.reasonForDelayForm.getRawValue();

      const formData = new FormData();
      formData.append('slaTriggerId', this.data?.slaId.toString());
      formData.append('file', file);
      formData.append('reasonForDelay', reasonForDelay);
      formData.append('details', moreDetails);
      formData.append(
        'newImplementationDate',
        format(implementationDate, 'yyyy-MM-dd')
      );

      this._bcService.resolveBCDelay(formData).subscribe({
        next: (val) => {
          this._toastr.success(
            `Business case stage ${this.data.stageName} reason for delay has been submitted successfully`
          );
          this._dialogRef.close();
        }
      });
    }
  }
}
