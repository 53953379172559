<div
  class="w-full h-screen bg-slate-200 p-4 flex flex-col justify-start overflow-y-auto"
>
  <bidvest-business-case-header>
    <span ngProjectAs="business-case-header-back-btn">
      <button
        mat-icon-button
        [routerLink]="routes['BUSINESS_CASES'] + '/' + businessCaseId"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
    </span>
    <span ngProjectAs="business-case-header-title">
      <p class="font-bold text-primary-blue">
        {{
          businessCase.businessCaseName
            ? businessCase.businessCaseName
            : 'Not available'
        }}
      </p>
    </span>
    <span ngProjectAs="business-case-header-number" #refNo>{{
      businessCase.referenceNumber
        ? businessCase.referenceNumber
        : 'Not available'
    }}</span>
    <span ngProjectAs="business-case-header-actions">
      <ng-container
        *ngIf="
          businessCase.businessCaseType === 'ENGINEERING_AND_SOFT_SERVICES'
        "
      >
        <ng-container
          *ngIf="
            (businessCase.activeStageName ===
              businessCaseStages.GATHER_ASSET_PRICE &&
              (businessCase.activeStageStatus ===
                businessCaseStageStatus.DRAFT ||
                businessCase.activeStageStatus ===
                  businessCaseStageStatus.REJECTED) &&
              isEditor) ||
            (businessCase.activeSecondStageName ===
              businessCaseStages.GATHER_ASSET_PRICE &&
              (businessCase.activeSecondStageStatus ===
                businessCaseStageStatus.DRAFT ||
                businessCase.activeSecondStageStatus ===
                  businessCaseStageStatus.REJECTED) &&
              isEditor)
          "
        >
          <button
            mat-flat-button
            color="accent"
            (click)="submitQuotesForCostVerificationForReview()"
          >
            Submit for Asset Pricing Review
          </button>
        </ng-container>

        <ng-container
          *ngIf="
            (businessCase.activeStageName ===
              businessCaseStages.GATHER_ASSET_PRICE &&
              businessCase.activeStageStatus ===
                businessCaseStageStatus.IN_REVIEW &&
              isReviewer) ||
            (businessCase.activeSecondStageName ===
              businessCaseStages.GATHER_ASSET_PRICE &&
              businessCase.activeSecondStageStatus ===
                businessCaseStageStatus.IN_REVIEW &&
              isReviewer)
          "
        >
          <button mat-stroked-button color="accent" class="mr-2">
            Reject Asset Pricing
          </button>
          <button
            mat-flat-button
            color="accent"
            (click)="submitQuotesForCostVerification()"
          >
            Approve Asset Pricing
          </button>
        </ng-container>
      </ng-container>

      <ng-container
        *ngIf="
          businessCase.businessCaseType !== 'ENGINEERING_AND_SOFT_SERVICES'
        "
      >
        <ng-container
          *ngIf="
            businessCase.activeStageName ===
              businessCaseStages.GATHER_ASSET_PRICE &&
            (businessCase.activeStageStatus === businessCaseStageStatus.DRAFT ||
              businessCase.activeStageStatus ===
                businessCaseStageStatus.REJECTED) &&
            isEditor
          "
        >
          <button
            mat-flat-button
            color="accent"
            (click)="submitQuotesForCostVerificationForReview()"
          >
            Submit for Asset Pricing Review
          </button>
        </ng-container>

        <ng-container
          *ngIf="
            businessCase.activeStageName ===
              businessCaseStages.GATHER_ASSET_PRICE &&
            businessCase.activeStageStatus ===
              businessCaseStageStatus.IN_REVIEW &&
            isReviewer
          "
        >
          <button mat-stroked-button color="accent" class="mr-2" (click)="rejectAssetPricing()">
            Reject Asset Pricing
          </button>
          <button
            mat-flat-button
            color="accent"
            (click)="submitQuotesForCostVerification()"
          >
            Approve Asset Pricing
          </button>
        </ng-container>
      </ng-container>
    </span>
  </bidvest-business-case-header>

  <div class="flex flex-col bg-white h-auto">
    <div class="w-full p-8 border-b-2 border-solid">
      <h4 class="font-bold text-primary-blue text-2xl">Asset pricing</h4>
      <p>Getting asset prices</p>
    </div>

    <div class="mt-4 rounded px-8 pb-8 flex flex-col justify-start">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-row justify-start items-center">
          <mat-form-field appearance="outline" class="w-72 mr-4">
            <mat-label>Search</mat-label>
            <input
              matInput
              [(ngModel)]="searchInput"
              (keyup.enter)="searchAssets()"
            />
            <button
              mat-icon-button
              matSuffix
              (click)="searchAssets()"
              [attr.aria-label]="'Search'"
            >
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <div class="flex flex-row justify-start items-center">
          <button
            class="mr-4"
            mat-button
            color="accent"
            (click)="showActivityLogDialog()"
          >
            <mat-icon>add</mat-icon>
            Add comment
          </button>
        </div>
      </div>
      <bidvest-asset-pricing-table
        [data]="{
          assets: verifiedAssets,
          businessCase: this.businessCase
        }"
        [isEditor]="isEditor"
        [isReviewer]="isReviewer"
        (assetDeclined)="this.runRFQModeCheck()"
      ></bidvest-asset-pricing-table>
    </div>
  </div>
</div>
