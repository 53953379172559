import {Pipe, PipeTransform} from '@angular/core';
import {
  BusinessCaseStagesEnum
} from "../../../../../bidvest-ui/features/src/lib/features/enums/business-case-stages.enum";

@Pipe({
  name: 'transformEnumToSentenceCase',
  standalone: true
})
export class TransformEnumToSentenceCasePipe implements PipeTransform {
  public transform(enumText: BusinessCaseStagesEnum) {
    if (!enumText) return '';
    const formatted = enumText.replace(/_/g, ' ').toLowerCase();
    return formatted.charAt(0).toUpperCase() + formatted.slice(1);
  }
}
