<table mat-table [dataSource]="dataSource" appearance="outline">

  <ng-container matColumnDef="serviceName">
    <th mat-header-cell *matHeaderCellDef>High level service description</th>
    <td mat-cell *matCellDef="let element">
      {{ element.serviceName }}
    </td>
    <td mat-footer-cell *matFooterCellDef style="font-weight: bold"> Total </td>
  </ng-container>

  <ng-container matColumnDef="serviceDescription">
    <th mat-header-cell *matHeaderCellDef>Service description</th>
    <td mat-cell *matCellDef="let element">
      {{ element.serviceDescription }}
    </td>
    <td mat-footer-cell *matFooterCellDef style="font-weight: bold"> </td>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <th mat-header-cell *matHeaderCellDef>Units</th>
    <td mat-cell *matCellDef="let element">
      {{ element.quantity }}
    </td>
    <td mat-footer-cell *matFooterCellDef style="font-weight: bold">{{ softServiceQuote?.totalItems }}</td>
  </ng-container>

  <ng-container matColumnDef="unitCost">
    <th mat-header-cell *matHeaderCellDef>Rate</th>
    <td mat-cell *matCellDef="let element">
      <div class="flex flex-row">
        <p class="text-primary-blue px-1">
          R </p>
        {{ element.unitCost }}
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef style="font-weight: bold">R {{ softServiceQuote?.subTotalSoftServicesPrice }}
    </td>
  </ng-container>

  <ng-container matColumnDef="vat">
    <th mat-header-cell *matHeaderCellDef>VAT</th>
    <td mat-cell *matCellDef="let element">
      <div class="flex flex-row">
        <p class="text-primary-blue px-1">R</p>
        {{ element.vat }} %
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef style="font-weight: bold">R {{ softServiceQuote?.totalSoftServicesVat }} </td>
  </ng-container>

  <ng-container matColumnDef="totalCost">
    <th mat-header-cell *matHeaderCellDef>Total planned- monthly</th>
    <td mat-cell *matCellDef="let element">
      <div class="flex flex-row">
        <p class="text-primary-blue px-1">
          R</p>
        {{ element.totalCost }}
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef style="font-weight: bold">R {{ softServiceQuote?.totalSoftServicesPrice }}
    </td>
  </ng-container>

  <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

  <mat-paginator aria-label="Select page"> </mat-paginator>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
