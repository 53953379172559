import { Component, OnInit } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import {
  backOfficeRouteKeys,
  BusinessCaseHeaderComponent,
  OverlayService
} from '@bidvest/shared';
import {
  ActivatedRoute,
  ParamMap,
  Router,
  RouterModule
} from '@angular/router';
import { IBusinessCaseDetails } from '../../interfaces/business-case-details.interface';
import { IBusinessCaseAsset } from '../../interfaces/business-case-asset.interface';
import { IBusinessCaseStageConfig } from '../../interfaces/business-case-stage-config.interface';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { AssetPricingTableComponent } from '../../components/tables/asset-pricing-table/asset-pricing-table.component';
import { BusinessCaseReviewPayload } from '../../interfaces/business-case-review-payload.interface';
import { BusinessCaseReviewStatus } from '../../enums/business-case-review-status.enum';
import { firstValueFrom } from 'rxjs';
import { BusinessCaseService } from '../../services/business-case.service';
import { AssetListService } from '../../services/asset-list.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { BusinessCaseActivityLogComponent } from '../../components/business-case-activity-log/business-case-activity-log.component';
import { IProcurementPriceList } from '../../interfaces/procurement-price-list.interface';
import { BusinessCaseStagesEnum } from '../../enums/business-case-stages.enum';
import { BusinessCaseStageStatus } from '../../enums/business-case-stage-status.enum';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ReasonForRejectionOverlayComponent
} from '../../components/dialogs/reason-for-rejection-overlay/reason-for-rejection-overlay.component';

@Component({
  selector: 'bidvest-procurement-to-price',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    BusinessCaseHeaderComponent,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    AssetPricingTableComponent,
    NgIf
  ],
  templateUrl: './procurement-to-price.component.html',
  styleUrl: './procurement-to-price.component.scss'
})
export class ProcurementToPriceComponent implements OnInit {
  public readonly routes = backOfficeRouteKeys;
  public businessCaseId!: number;
  public currentStageName!: string;
  public businessCase!: IBusinessCaseDetails;
  public verifiedAssets: Array<IBusinessCaseAsset> = [];
  public assetsForPricing: Array<IBusinessCaseAsset> = [];
  public businessCaseStagesConfigs: Array<IBusinessCaseStageConfig> = [];
  public currentPageIndex = 0;
  public pageSize = 100;
  public isRFQMode = false;
  public isEditor!: boolean;
  public isReviewer!: boolean;
  public searchInput!: string;
  public filterInput!: string;
  public filterOptions: Array<string> = ['All', 'Available', 'Missing'];
  public assetListData: Array<IProcurementPriceList> = [];
  public lockButton: boolean = true;
  public businessCaseStages = BusinessCaseStagesEnum;
  public businessCaseStageStatus = BusinessCaseStageStatus;

  public constructor(
    private readonly _dialog: MatDialog,
    private readonly _route: ActivatedRoute,
    private readonly _businessCaseService: BusinessCaseService,
    private readonly _assetListService: AssetListService,
    private readonly _loader: NgxUiLoaderService,
    private readonly _toastr: ToastrService,
    private readonly _router: Router,
    private readonly _overlayService: OverlayService
  ) {}

  public async ngOnInit(): Promise<void> {
    const routeParams: ParamMap = await firstValueFrom(this._route.paramMap);
    this.businessCaseId = routeParams.get('id') as unknown as number;

    this.currentStageName = (
      await firstValueFrom(this._route.queryParamMap)
    ).get('stage') as string;

    try {
      this.businessCase = await firstValueFrom(
        this._businessCaseService.fetchBusinessCaseById(this.businessCaseId)
      );
    } catch (error) {
      this._toastr.error(
        `There was an error fetching Business Case ${this.businessCaseId}`
      );
    }

    try {
      this.businessCaseStagesConfigs = await firstValueFrom(
        this._businessCaseService.fetchBusinessCaseStagesConfigs(
          this.businessCaseId
        )
      );
    } catch (error) {
      this._toastr.error(
        `There was an error fetching business case configurations`
      );
    }

    try {
      this.assetsForPricing = (
        await this._assetListService.getAssetsForPricing(this.businessCaseId)
      ).assetRetrievalList as unknown as Array<IBusinessCaseAsset>;
    } catch (error) {
      this._toastr.error(`There was an error fetching the asset list`);
    }

    this.isEditor = this._businessCaseService.isEditor(
      this.businessCaseStagesConfigs,
      this.businessCase, //TODO remove unused param
      this.currentStageName
    );

    this.isReviewer = this._businessCaseService.isReviewer(
      this.businessCaseStagesConfigs,
      this.businessCase, //TODO remove unused param
      this.currentStageName
    );
  }

  public async searchAssets(): Promise<void> {
    this._loader.start();
    try {
      this.assetsForPricing = (
        await this._assetListService.getAssetsForPricing(this.businessCaseId)
      ).assetRetrievalList as unknown as Array<IBusinessCaseAsset>;
      this._loader.stop();
    } catch (error) {
      this._loader.stop();
      this._toastr.error('There was an error fetching the asset list');
    }
  }

  public async filterAssets(event: MatSelectChange): Promise<void> {
    this._loader.start();
    try {
      this.assetsForPricing = (
        await this._assetListService.getAssetsForPricing(this.businessCaseId)
      ).assetRetrievalList as unknown as Array<IBusinessCaseAsset>;
      this._loader.stop();
    } catch (error) {
      this._loader.stop();
      this._toastr.error('There was an error fetching the asset list.');
    }
  }

  public async submitPricesForReview(): Promise<void> {
    this._loader.start();

    try {
      this._loader.start();
      if (this.businessCase?.activeSecondStageName) {
        await firstValueFrom(
          this._businessCaseService.submitBusinessCaseForReview({
            businessCaseId: this.businessCaseId as number,
            businessCaseStageId: this.businessCase
              ?.activeSecondStageId as number
          })
        );
      } else {
        await firstValueFrom(
          this._businessCaseService.submitBusinessCaseForReview({
            businessCaseId: this.businessCaseId as number,
            businessCaseStageId: this.businessCase?.activeStageId as number
          })
        );
      }
      this._loader.stop();
      this._toastr.success(
        `Asset prices have been submitted for review successfully`
      );

      await this._router.navigateByUrl(
        `${this.routes['BUSINESS_CASES']}/${this.businessCaseId}`
      );
    } catch (error) {
      this._loader.stop();
      this._toastr.error(`There was an error submitting prices for review`);
    }
  }

  public async submitUpdatedPricesForReview(): Promise<void> {
    try {
      this._loader.start();

      this._assetListService.submitArrayAssetPrices(
        this.businessCaseId,
        this.assetListData
      );

      if (this.businessCase?.activeSecondStageName) {
        await firstValueFrom(
          this._businessCaseService.submitBusinessCaseForReview({
            businessCaseId: this.businessCaseId as number,
            businessCaseStageId: this.businessCase
              ?.activeSecondStageId as number
          })
        );
      } else {
        await firstValueFrom(
          this._businessCaseService.submitBusinessCaseForReview({
            businessCaseId: this.businessCaseId as number,
            businessCaseStageId: this.businessCase?.activeStageId as number
          })
        );
      }

      this._loader.stop();
      this._toastr.success(`Asset prices have been submitted successfully`);

      await this._router.navigateByUrl(
        `${this.routes['BUSINESS_CASES']}/${this.businessCaseId}`
      );
    } catch (error) {
      this._loader.stop();
      this._toastr.error(
        `There was an issue with submitting asset prices for review`
      );
    }
  }

  public async approveUpdatedPrices(): Promise<void> {
    this.compiledPricingAssetArray();

    let payload: BusinessCaseReviewPayload;
    try {
      this._loader.start();
      if (this.businessCase?.activeSecondStageName) {
        payload = {
          businessCaseId: this.businessCaseId as number,
          businessCaseStageId: this.businessCase?.activeSecondStageId as number,
          businessCaseStageReviewStatus: BusinessCaseReviewStatus.APPROVED,
          rejectedReason: '',
          moreDetails: ''
        };
      } else {
        payload = {
          businessCaseId: this.businessCaseId as number,
          businessCaseStageId: this.businessCase?.activeStageId as number,
          businessCaseStageReviewStatus: BusinessCaseReviewStatus.APPROVED,
          rejectedReason: '',
          moreDetails: ''
        };
      }
      await this._assetListService.approveProcuredAssets(
        this.businessCaseId,
        this.assetListData
      );
      await firstValueFrom(
        this._businessCaseService.approveBusinessCase(payload)
      );

      this._loader.stop();
      this._toastr.success(`Asset prices have been submitted successfully`);

      await this._router.navigateByUrl(
        `${this.routes['BUSINESS_CASES']}/${this.businessCaseId}`
      );
    } catch (error) {
      this._loader.stop();
      this._toastr.error(
        `There was an issue approving Business Case ${this.businessCaseId}`
      );
    }
  }

  public async rejectUpdatedPrices(): Promise<void> {
    const dialogRef: MatDialogRef<ReasonForRejectionOverlayComponent> =
      this._dialog.open(ReasonForRejectionOverlayComponent, {
        width: '600px',
        height: '450px'
      });

    dialogRef
      .beforeClosed()
      .subscribe(async (data: { reason: string; details: string }) => {
        let payload: BusinessCaseReviewPayload;


        if (this.businessCase?.activeSecondStageName) {
          payload = {
            businessCaseId: this.businessCaseId as number,
            businessCaseStageId: this.businessCase?.activeSecondStageId as number,
            businessCaseStageReviewStatus: BusinessCaseReviewStatus.REJECTED,
            rejectedReason: data.reason,
            moreDetails: data.details
          };
        } else {
          payload = {
            businessCaseId: this.businessCaseId as number,
            businessCaseStageId: this.businessCase?.activeStageId as number,
            businessCaseStageReviewStatus: BusinessCaseReviewStatus.REJECTED,
            rejectedReason: data.reason,
            moreDetails: data.details
          };
        }

        try {
          this._loader.start();

          await firstValueFrom(
            this._businessCaseService.rejectBusinessCase(payload)
          );

          this._loader.stop();
          this._toastr.success(
            `Business case ${payload.businessCaseId} has been rejected successfully!`
          );

          this._router.navigateByUrl(this.routes['BUSINESS_CASES']);
        } catch (error) {
          this._toastr.error(
            `There was as issue rejecting the business case with id ${payload.businessCaseId}`
          );
        }

        this._loader.stop();
        await this._router.navigateByUrl(this.routes['BUSINESS_CASES']);
      });
  }

  public handleAssetData(data: IProcurementPriceList[]) {
    this.assetListData = data;
    if (this.assetListData.length > 0) {
      this.lockButton = false;
    }
  }

  public compiledPricingAssetArray() {
    const updatedAssetList: IProcurementPriceList[] = this.assetsForPricing.map(
      (assets) => ({
        id: assets.id,
        monthlyFee: assets.monthlyFee,
        reactiveFeeWithWarranty: assets.reactiveFeeWithWarranty,
        reactiveFeeWithoutWarranty: assets.reactiveFeeWithoutWarranty
      })
    );
    this.assetListData = updatedAssetList;
  }

  public showActivityLogDialog(): void {
    this._overlayService.open(BusinessCaseActivityLogComponent, {
      key: 'businessCaseId',
      value: this.businessCaseId
    });
  }
}
