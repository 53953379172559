import { CommonModule, NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, ParamMap, Router, RouterLink } from '@angular/router';
import { ReasonForRejectionOverlayComponent } from '@bidvest/features';
import {
  backOfficeRouteKeys,
  BusinessCaseHeaderComponent,
  OverlayService
} from '@bidvest/shared';
import { IBusinessCaseDetails } from '../../interfaces/business-case-details.interface';
import { SoftServiceCategories } from '../../enums/soft-service-categories.enum';
import { IBusinessCaseStageConfig } from '../../interfaces/business-case-stage-config.interface';
import { SoftServicesService } from '../../services/soft-services.service';
import { ToastrService } from 'ngx-toastr';
import { BusinessCaseService } from '../../services/business-case.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { firstValueFrom } from 'rxjs';
import { IAddSoftServicesPayload } from '../../models/add-soft-services-payload.interface';
import { IViewSoftServices } from '../../models/view-soft-services';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BusinessCaseReviewPayload } from '../../interfaces/business-case-review-payload.interface';
import { BusinessCaseReviewStatus } from '../../enums/business-case-review-status.enum';
import { BusinessCaseActivityLogComponent } from '../../components/business-case-activity-log/business-case-activity-log.component';
import { AddSoftServiceOverlayComponent } from '../../components/overlays/add-soft-services-overlay/add-soft-services-overlay.component';
import { AddSoftServicesUpdate } from '../../models/add-soft-services-update.interface';
import { BusinessCaseStagesEnum } from '../../enums/business-case-stages.enum';
import { BusinessCaseStageStatus } from '../../enums/business-case-stage-status.enum';

@Component({
  selector: 'bidvest-soft-services',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatIconModule,
    MatPaginatorModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    FormsModule,
    MatTooltip,
    BusinessCaseHeaderComponent,
    RouterLink,
    NgIf
  ],
  templateUrl: './soft-services.component.html',
  styleUrl: './soft-services.component.scss'
})
export class SoftServicesComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(MatPaginator) public paginator!: MatPaginator;
  // public dataSource = new MatTableDataSource<IRateCardDataType>([]);
  public dataSource = new MatTableDataSource<any>([]);
  public rateCardData!: IViewSoftServices;
  public softServicesData: Array<AddSoftServicesUpdate> = [];
  public businessCaseId!: number;
  public currentStageName!: string;
  public businessCaseDetails: IBusinessCaseDetails | undefined;
  public size: number = 100;
  public searchValue: string | null = null;
  public categoryValue: string = '';
  public resultsLength: number = 0;
  public filterCategoriesKeys = Object.keys(SoftServiceCategories);
  public displayedColumns: Array<string> = [
    'code',
    'serviceName',
    'category',
    'subCategory',
    'unitCost',
    'uom',
    'quantity'
  ];
  public routes: { [key: string]: string } = backOfficeRouteKeys;
  public businessCaseStagesConfigs: Array<IBusinessCaseStageConfig> = [];
  public isEditor!: boolean;
  public isReviewer!: boolean;
  public disableButton: boolean = true;
  public businessCaseStages = BusinessCaseStagesEnum;
  public businessCaseStageStatus = BusinessCaseStageStatus;

  constructor(
    private readonly _softService: SoftServicesService,
    private readonly _route: ActivatedRoute,
    private readonly _toastr: ToastrService,
    private readonly _businessCaseService: BusinessCaseService,
    private readonly _router: Router,
    private readonly _loader: NgxUiLoaderService,
    private readonly _dialog: MatDialog,
    private readonly _overlayService: OverlayService
  ) {}

  public async ngOnInit(): Promise<void> {
    const routeParams: ParamMap = await firstValueFrom(this._route.paramMap);
    this.businessCaseId = routeParams.get('id') as unknown as number;
    this.currentStageName = (
      await firstValueFrom(this._route.queryParamMap)
    ).get('stage') as string;

    this.subscribeTable();

    try {
      this.businessCaseDetails = await firstValueFrom(
        this._businessCaseService.fetchBusinessCaseById(this.businessCaseId)
      );

      await this.getAvailableServices();
      await this.getAddedSoftServices();
      this.disableSubmission();

      this.businessCaseStagesConfigs = await firstValueFrom(
        this._businessCaseService.fetchBusinessCaseStagesConfigs(
          this.businessCaseId
        )
      );

      this.isEditor = this._businessCaseService.isEditor(
        this.businessCaseStagesConfigs,
        this.businessCaseDetails,
        this.currentStageName
      );

      this.isReviewer = this._businessCaseService.isReviewer(
        this.businessCaseStagesConfigs,
        this.businessCaseDetails,
        this.currentStageName
      );
    } catch (error) {
      console.log(error);
    }
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.dataSource.data = changes['data'].currentValue;
    this.disableSubmission();
  }

  public async triggerChange(): Promise<void> {
    await this.getAvailableServices();
  }

  public subscribeTable() {
    this._softService.refreshTable$.subscribe(() => {
      this.getAvailableServices();
    });
  }

  public async getAvailableServices(): Promise<void> {
    const businessCaseDetails =
      await this._softService.fetchBusinessCaseSoftServices(
        this.businessCaseId
      );
    this.dataSource = new MatTableDataSource(businessCaseDetails);
    this.disableSubmission();

    this.resultsLength = businessCaseDetails.length;
  }

  public async getAddedSoftServices(): Promise<void> {
    this.softServicesData =
      await this._softService.fetchBusinessCaseSoftServices(
        this.businessCaseId
      );
    const inputElements = document.querySelectorAll(
      'input[data-id]'
    ) as NodeListOf<HTMLInputElement>;
    // loop through and update the value
    this.softServicesData.forEach((value) => {
      inputElements.forEach((input) => {
        if (Number(input.getAttribute('data-id')) === value.rateCard.id) {
          input.value = String(value.quantity);
        }
      });
    });
  }

  public async applySearch(event: Event): Promise<void> {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue;
  }

  public transformToSentenceCase(value: string): string {
    if (!value) return '';
    const formatted = value.replace(/_/g, ' ').toLowerCase();
    return formatted.charAt(0).toUpperCase() + formatted.slice(1);
  }

  public disableSubmission() {
    if (this.dataSource.data.length < 1) {
      this.disableButton = true;
    } else {
      this.disableButton = false;
    }
  }

  public async submit(): Promise<void> {
    try {
      this._loader.start();

      const payload: Array<IAddSoftServicesPayload> = [];

      const inputElements = document.querySelectorAll(
        'input[data-id]'
      ) as NodeListOf<HTMLInputElement>;
      inputElements.forEach((input) => {
        if (input.value && Number(input.value) >= 0) {
          payload.push({
            rateCardId: Number(input.getAttribute('data-id')),
            quantity: Number(input.value)
          });
        }
      });

      if (payload.length > 0) {
        await this._softService.addSoftService(payload, this.businessCaseId);
        this._loader.stop();

        this._toastr.success(
          'The soft services were successfully added to the business case.'
        );
        await this.getAddedSoftServices();
      } else {
        this._toastr.error(
          'Please ensure you enter a quantity for at least one soft service item.'
        );
      }
    } catch (error) {
      this._loader.stop();
      this._toastr.error(
        `There was an error updating business case ${this.businessCaseDetails?.referenceNumber} soft services.`
      );
    }
  }

  public async review(): Promise<void> {
    try {
      this._loader.start();

      await firstValueFrom(
        this._businessCaseService.submitBusinessCaseForReview({
          businessCaseId: this.businessCaseId as number,
          businessCaseStageId: this.businessCaseDetails?.activeStageId as number
        })
      );

      this._loader.stop();

      this._toastr.success(
        `Business Case ${this.businessCaseDetails?.referenceNumber} has been submitted for review`
      );

      await this._router.navigateByUrl(
        this.routes['BUSINESS_CASES'] + '/' + this.businessCaseId
      );
    } catch (error) {
      this._toastr.error(
        `There was an error submitting business case ${this.businessCaseDetails?.referenceNumber} for review.`
      );
    } finally {
      this._loader.stop();
    }
  }

  public softServicesOverlay() {
    this._dialog.open(AddSoftServiceOverlayComponent, {
      minWidth: '100vw',
      height: '100vh',
      data: {
        businessCaseId: this.businessCaseId as number,
        businessCaseDetails: this.businessCaseDetails
      }
    });
  }

  public rejectSoftServicesStage(): void {
    const dialogRef: MatDialogRef<ReasonForRejectionOverlayComponent> =
      this._dialog.open(ReasonForRejectionOverlayComponent, {
        width: '600px',
        height: '450px'
      });

    dialogRef
      .beforeClosed()
      .subscribe(async (data: { reason: string; details: string }) => {
        const payload: BusinessCaseReviewPayload = {
          businessCaseId: this.businessCaseDetails?.id as number,
          businessCaseStageId: this.businessCaseDetails
            ?.activeStageId as number,
          businessCaseStageReviewStatus: BusinessCaseReviewStatus.REJECTED,
          rejectedReason: data.reason,
          moreDetails: data.details
        };

        try {
          this._loader.start();

          await firstValueFrom(
            this._businessCaseService.rejectBusinessCase(payload)
          );

          this._loader.stop();
          this._toastr.success(
            `Business case ${payload.businessCaseId} has been rejected.`
          );

          await this._router.navigateByUrl(this.routes['BUSINESS_CASES']);
        } catch (error) {
          this._loader.stop();
          this._toastr.error(
            `There was as issue rejecting the business case with id ${payload.businessCaseId}`
          );
        }
      });
  }

  public async approveSoftServicesStage(): Promise<void> {
    const payload: BusinessCaseReviewPayload = {
      businessCaseId: this.businessCaseDetails?.id as number,
      businessCaseStageId: this.businessCaseDetails?.activeStageId as number,
      businessCaseStageReviewStatus: BusinessCaseReviewStatus.APPROVED,
      rejectedReason: '',
      moreDetails: ''
    };

    try {
      this._loader.start();

      await firstValueFrom(
        this._businessCaseService.approveBusinessCase(payload)
      );

      this._loader.stop();
      this._toastr.success(
        `Business case ${payload.businessCaseId} has been approved successfully.`
      );

      await this._router.navigateByUrl(this.routes['BUSINESS_CASES']);
    } catch (error) {
      this._loader.stop();

      this._toastr.error(
        `There was as issue approving the business case with id ${payload.businessCaseId}`
      );
    }
  }

  public showActivityLogDialog(): void {
    this._overlayService.open(BusinessCaseActivityLogComponent, {
      key: 'businessCaseId',
      value: this.businessCaseId
    });
  }
}
