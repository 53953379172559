import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  backOfficeRouteKeys,
  BusinessCaseHeaderComponent
} from '@bidvest/shared';
import {ActivatedRoute, ParamMap, Router, RouterLink} from '@angular/router';
import {IBusinessCaseDetails} from '../../interfaces/business-case-details.interface';
import {BusinessCaseService} from '../../services/business-case.service';
import {firstValueFrom} from 'rxjs';
import {IBusinessCaseStageConfig} from '../../interfaces/business-case-stage-config.interface';
import {MatIcon} from '@angular/material/icon';
import {MatTooltip} from '@angular/material/tooltip';
import {
  EngineeringServiceQuoteTableComponent
} from '../../components/tables/engineering-service-quote-table/engineering-service-quote-table.component';
import {MatButtonModule, MatIconButton} from '@angular/material/button';
import {IBusinessCaseEngineeringServiceQuote} from '../../models/business-case-engineering-service-quote';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {
  ReasonForRejectionOverlayComponent
} from '../../components/dialogs/reason-for-rejection-overlay/reason-for-rejection-overlay.component';
import {BusinessCaseReviewPayload} from '../../interfaces/business-case-review-payload.interface';
import {BusinessCaseReviewStatus} from '../../enums/business-case-review-status.enum';
import {BusinessCaseStagesEnum} from '../../enums/business-case-stages.enum';

@Component({
  selector: 'bidvest-business-cases',
  standalone: true,
  imports: [
    CommonModule,
    BusinessCaseHeaderComponent,
    MatIcon,
    MatIconButton,
    MatTooltip,
    RouterLink,
    MatButtonModule,
    EngineeringServiceQuoteTableComponent
  ],
  templateUrl: './engineering-services-quotes.component.html',
  styleUrl: './engineering-services-quotes.component.scss'
})
export class EngineeringServicesQuotesComponent implements OnInit {
  public routes: { [key: string]: string } = backOfficeRouteKeys;
  public businessCaseStagesConfigs: Array<IBusinessCaseStageConfig> = [];
  public businessCaseId!: number;
  public engineeringServiceQuote:
    | IBusinessCaseEngineeringServiceQuote
    | undefined;
  public businessCase: IBusinessCaseDetails | null = null;
  public isReviewer!: boolean;
  public isEditor!: boolean;
  public businessCaseStages = BusinessCaseStagesEnum;
  public currentStageName!: string;

  constructor(
    private readonly _loader: NgxUiLoaderService,
    private readonly _route: ActivatedRoute,
    private readonly _businessCaseService: BusinessCaseService,
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
    private readonly _toaster: ToastrService
  ) {
  }

  public async ngOnInit(): Promise<void> {
    await this._loader.start();
    const routeParams: ParamMap = await firstValueFrom(this._route.paramMap);
    this.businessCaseId = routeParams.get('id') as unknown as number;

    this.currentStageName = (
      await firstValueFrom(this._route.queryParamMap)
    ).get('stage') as string;

    try {
      this.businessCaseStagesConfigs = await firstValueFrom(
        this._businessCaseService.fetchBusinessCaseStagesConfigs(
          this.businessCaseId
        )
      );
      await this.loadBusinessCaseSoftServices();

      this.businessCase = await firstValueFrom(
        this._businessCaseService.fetchBusinessCaseById(this.businessCaseId)
      );

      this.isEditor = this._businessCaseService.isEditor(
        this.businessCaseStagesConfigs,
        this.businessCase,
        this.currentStageName
      );

      this.isReviewer = this._businessCaseService.isReviewer(
        this.businessCaseStagesConfigs,
        this.businessCase,
        this.currentStageName
      );
    } catch (error) {
      this._toaster.error(
        `There was an error while fetching business case ${this.businessCaseId}`
      );
    } finally {
      this._loader.stop();
    }
  }

  public rejectSoftServiceQuote(): void {
    const dialogRef: MatDialogRef<ReasonForRejectionOverlayComponent> =
      this._dialog.open(ReasonForRejectionOverlayComponent, {
        width: '600px',
        height: '450px'
      });

    dialogRef
      .beforeClosed()
      .subscribe(async (data: { reason: string; details: string }) => {
        let payload: BusinessCaseReviewPayload;

        if (this.businessCase?.activeSecondStageName) {
          payload = {
            businessCaseId: this.businessCaseId as number,
            businessCaseStageId: this.businessCase?.activeSecondStageId as number,
            businessCaseStageReviewStatus: BusinessCaseReviewStatus.REJECTED,
            rejectedReason: data.reason,
            moreDetails: data.details
          };
        } else {
          payload = {
            businessCaseId: this.businessCaseId as number,
            businessCaseStageId: this.businessCase?.activeStageId as number,
            businessCaseStageReviewStatus: BusinessCaseReviewStatus.REJECTED,
            rejectedReason: data.reason,
            moreDetails: data.details
          };
        }

        try {
          this._loader.start();

          await firstValueFrom(
            this._businessCaseService.rejectBusinessCase(payload)
          );

          this._loader.stop();
          this._toaster.success(
            `Business case ${payload.businessCaseId} has been rejected.`
          );

          await this._router.navigateByUrl(this.routes['BUSINESS_CASES']);
        } catch (error) {
          this._toaster.error(
            `There was as issue with rejecting business case ${payload.businessCaseId}`
          );
        } finally {
          this._loader.stop();
        }
      });
  }

  public async approveSoftServiceQuote(): Promise<void> {
    this._loader.start();

    let payload: BusinessCaseReviewPayload;
    try {
      this._loader.start();

      if (this.businessCase?.activeSecondStageName) {
        payload = {
          businessCaseId: this.businessCaseId as number,
          businessCaseStageId: this.businessCase?.activeSecondStageId as number,
          businessCaseStageReviewStatus: BusinessCaseReviewStatus.APPROVED,
          rejectedReason: '',
          moreDetails: ''
        };
      } else {
        payload = {
          businessCaseId: this.businessCaseId as number,
          businessCaseStageId: this.businessCase?.activeStageId as number,
          businessCaseStageReviewStatus: BusinessCaseReviewStatus.APPROVED,
          rejectedReason: '',
          moreDetails: ''
        };
      }
      await firstValueFrom(
        this._businessCaseService.approveBusinessCase(payload)
      );

      this._loader.stop();
      this._toaster.success(
        `Business case ${payload.businessCaseId} has been approved successfully.`
      );

      await this._router.navigateByUrl(this.routes['BUSINESS_CASES']);
    } catch (error) {
      this._toaster.error(
        `There was an issue with approving business case ${this.businessCase?.id}`
      );
    } finally {
      this._loader.stop();
    }
  }

  public async review(): Promise<void> {
    try {
      this._loader.start();

      if (this.businessCase?.activeSecondStageName) {
        await firstValueFrom(
          this._businessCaseService.submitBusinessCaseForReview({
            businessCaseId: this.businessCaseId as number,
            businessCaseStageId: this.businessCase
              ?.activeSecondStageId as number
          })
        );
      } else {
        await firstValueFrom(
          this._businessCaseService.submitBusinessCaseForReview({
            businessCaseId: this.businessCaseId as number,
            businessCaseStageId: this.businessCase?.activeStageId as number
          })
        );
      }

      this._loader.stop();

      this._toaster.success(
        `Business Case ${this.businessCase?.referenceNumber} has been submitted for review`
      );

      await this._router.navigateByUrl(
        this.routes['BUSINESS_CASES'] + '/' + this.businessCaseId
      );
    } catch (error) {
      this._toaster.error(
        `There was an error submitting business case ${this.businessCase?.referenceNumber} for review.`
      );
    } finally {
      this._loader.stop();
    }
  }

  public returnToSoftServicesStage() {
    this._router.navigateByUrl(
      `business-cases/${this.businessCaseId}/soft-services`
    );
  }

  public checkCurrentStage(): boolean {
    if (this.businessCase?.businessCaseType === 'ENGINEERING_AND_SOFT_SERVICES' &&
      this.businessCase?.activeSecondStageName === this.currentStageName) {
      return true;
    } else if (this.businessCase?.businessCaseType !== 'ENGINEERING_AND_SOFT_SERVICES' &&
      this.businessCase?.activeStageName === this.currentStageName) {
      return true;
    } else {
      return false;
    }

  }

  public extractRejectionReason(reason: string | undefined) {
    if (reason) {
      const extractedReason = reason.match(/\((.*?)\)/);
      return extractedReason?.[1];
    }
    return;
  }

  private async loadBusinessCaseSoftServices(): Promise<void> {
    this.engineeringServiceQuote = await firstValueFrom(
      this._businessCaseService.fetchBusinessCaseHardServiceQuote(
        this.businessCaseId
      )
    );
  }
}
