<div class="mb-4">
  <h4 class="text-primary-blue font-bold text-2xl">Change Notes</h4>
</div>
<bidvest-business-cases-metrics-panel
  #metricsPanel
  [data]="overviewTotals"
  (dateRangeSelect)="updateTableDateRange($event)"
></bidvest-business-cases-metrics-panel>
<div class="flex flex-col pt-8 pb-4">
  <div class="w-full mb-2 flex flex-row items-center justify-start">
    <span class="m-0 text-sm font-medium mr-2"
      >Last updated: {{ getLastUpdated() | date: 'short' }}</span
    >

    <button mat-button color="accent" (click)="refreshBusinessCaseTableData()">
      <mat-icon>refresh</mat-icon>
      Refresh
    </button>
  </div>
  <div class="flex justify-between items-center">
    <div class="flex flex-row justify-start">
      <mat-form-field class="mr-4" appearance="outline">
        <mat-label>Search</mat-label>
        <input
          style="font-size: 12px"
          type="text"
          matInput
          placeholder="Search"
          name="searchTerm"
          [(ngModel)]="searchTerm"
        />
        <mat-icon matSuffix (click)="search()">search</mat-icon>
      </mat-form-field>

      <mat-form-field class="mr-4" appearance="outline">
        <mat-label>Filter By Stage</mat-label>
        <mat-select [(ngModel)]="businessCaseStage">
          <mat-option [value]="" (click)="filterByStage('')">ALL</mat-option>
          @for (category of filterCategoriesKeys; track category) {
          <mat-option [value]="category" (click)="filterByStage(category)">{{
            category.split('_').join(' ')
          }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field class="mr-6" appearance="outline">
        <mat-label>Filter By Status</mat-label>
        <mat-select [(ngModel)]="businessCaseStatus">
          <mat-option [value]="" (click)="filterByStatus()">All</mat-option>
          <mat-option [value]="'delayed'" (click)="filterByStatus()"
            >Delayed</mat-option
          >
          <mat-option [value]="'overdue'" (click)="filterByStatus()"
            >Overdue</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex-row flex items-center justify-center">
      <button class="bidvest-stroked-btn mr-4" (click)="resetTableFilters()">
        Reset filters
      </button>
      <button class="bidvest-flat-btn" (click)="showNewBusinessCaseDialog()">
        <mat-icon>add</mat-icon>
        New Change Note
      </button>
    </div>
  </div>
</div>
<bidvest-business-cases-table
  [data]="data"
  (actions)="handleBusinessCaseAction($event)"
></bidvest-business-cases-table>
