export enum BusinessCaseStagesEnum {
  SETUP = 'SETUP',
  COST_SOFT_SERVICES = 'COST_SOFT_SERVICES',
  COST_ENGINEERING_SERVICES = 'COST_ENGINEERING_SERVICES',
  VERIFY_ASSET_LIST = 'VERIFY_ASSET_LIST',
  GATHER_ASSET_PRICE = 'GATHER_ASSET_PRICE',
  PROCUREMENT_TO_PRICE = 'PROCUREMENT_TO_PRICE',
  PREPARE_ENGINEERING_QUOTE = 'PREPARE_ENGINEERING_QUOTE',
  ENGINEERING_REVIEW = 'ENGINEERING_REVIEW',
  GOVERNANCE_REVIEW = 'GOVERNANCE_REVIEW',
  LEGAL_REVIEW = 'LEGAL_REVIEW',
  CUSTOMER_OPERATIONS_REVIEW = 'CUSTOMER_OPERATIONS_REVIEW',
  CUSTOMER_ENGINEERING_REVIEW = 'CUSTOMER_ENGINEERING_REVIEW',
  CUSTOMER_FINAL_REVIEW = 'CUSTOMER_FINAL_REVIEW',
  GENERATE_CHANGE_NOTE = 'GENERATE_CHANGE_NOTE'
}
