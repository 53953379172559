import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogRef
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  TransformEnumToSentenceCasePipe
} from "../../../../../../../../shared/src/lib/shared/pipes/transform-enum-to-sentence-case.pipe";
import {BusinessCaseStagesEnum} from "../../../enums/business-case-stages.enum";

@Component({
  selector: 'bidvest-delay-notice-dialog',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatDialogContent, MatButtonModule, TransformEnumToSentenceCasePipe],
  templateUrl: './delay-notice-dialog.component.html',
  styleUrl: './delay-notice-dialog.component.scss'
})
export class DelayNoticeDialogComponent {
  public readonly data = inject<{ delayDayCount: string; stageName: BusinessCaseStagesEnum }>(
    MAT_DIALOG_DATA
  );
  private readonly _dialogRef: MatDialogRef<DelayNoticeDialogComponent> =
    inject(MatDialogRef);

  closeDialog(isUpdate: boolean): void {
    this._dialogRef.close(isUpdate);
  }
}
