import { Injectable } from '@angular/core';
import {
  IBusinessCaseStageConfig
} from "../../../../../bidvest-ui/features/src/lib/features/interfaces/business-case-stage-config.interface";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  DelayNoticeDialogComponent
} from "../../../../../bidvest-ui/features/src/lib/features/components/dialogs/delay-notice-dialog/delay-notice-dialog.component";
import {firstValueFrom} from "rxjs";
import {
  ReasonForDelayDialogComponent
} from "../../../../../bidvest-ui/features/src/lib/features/components/dialogs/reason-for-delay-dialog/reason-for-delay-dialog.component";
import {
  IBusinessCaseDetails
} from "../../../../../bidvest-ui/features/src/lib/features/interfaces/business-case-details.interface";
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class DelayStatusPopupService {
  constructor(
    private readonly _toastr: ToastrService,
    private readonly _dialog: MatDialog
  ) {}

  public async checkBCDelayStatus(stageConfigs: IBusinessCaseStageConfig[] | undefined, businessCaseDetails: IBusinessCaseDetails ): Promise<void> {
    const activeStage: IBusinessCaseStageConfig | undefined =
      stageConfigs?.find((stage) => stage.stageActive);

    if (
      activeStage &&
      activeStage.stageName === businessCaseDetails?.activeStageName &&
      activeStage.canEdit
    ) {
      const slaTrigger = businessCaseDetails?.slaTriggers.find((sla) => sla.businessCaseStageName ===
        businessCaseDetails.activeStageName);

      if (businessCaseDetails.activeStageDelayed && slaTrigger) {
        const dialogRef: MatDialogRef<DelayNoticeDialogComponent> =
          this._dialog.open(DelayNoticeDialogComponent, {
            width: '500px',
            height: '500px',
            hasBackdrop: true,
            disableClose: true,
            data: {
              delayDayCount: businessCaseDetails.activeStageDelayedDays,
              stageName: businessCaseDetails.activeStageName
            }
          });
        const actionType = await firstValueFrom(dialogRef.beforeClosed());
        if (!actionType) {
          this._dialog.open(ReasonForDelayDialogComponent, {
            width: '700px',
            height: '700px',
            hasBackdrop: true,
            disableClose: true,
            data: {
              slaId: slaTrigger.id,
              stageName: businessCaseDetails.activeStageName
            }
          });
        }
      }
    }
  }
}
