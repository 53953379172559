import {
  Component,
  effect,
  input,
  InputSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { IBusinessCaseStageConfig } from '../../interfaces/business-case-stage-config.interface';
import { IBusinessCaseOverview } from '../../interfaces/business-case-overview.interface';
import { IBusinessCaseDetails } from '../../interfaces/business-case-details.interface';
import { BusinessCaseStagesEnum } from '../../enums/business-case-stages.enum';
import {BusinessCaseStageStatus} from "../../enums/business-case-stage-status.enum";

@Component({
  selector: 'bidvest-business-case-stage-indicator',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './business-case-stage-indicator.component.html',
  styleUrl: './business-case-stage-indicator.component.scss'
})
export class BusinessCaseStageIndicatorComponent {
  public stages: InputSignal<Array<IBusinessCaseStageConfig>> = input<
    Array<IBusinessCaseStageConfig>
  >([]);

  public currentStage: InputSignal<IBusinessCaseStageConfig | undefined> =
    input<IBusinessCaseStageConfig>();

  public overview: InputSignal<IBusinessCaseOverview | undefined> = input();

  public businessCaseDetails: InputSignal<IBusinessCaseDetails | undefined> =
    input<IBusinessCaseDetails>();

  private _stages: Array<IBusinessCaseStageConfig> = [];
  private _activeStagesCount: number = 0;

  constructor(private readonly _router: Router) {
    effect(() => {
      this._activeStagesCount = this.stages().filter(
        (stage) => stage.stageActive === true
      ).length;
      this._stages = this.stages();
    });
  }

  public goToStageView(stage: IBusinessCaseStageConfig): void {
    switch (this.currentStage()?.stageName) {
      case 'SETUP':
        if (
          this.currentStage()?.stageActive ||
          this.currentStage()?.stageCompleted
        ) {
          if (this.currentStage()?.canEdit) {
            this._router.navigateByUrl(
              this._getRoute({
                changeNoteId: this.overview()?.id as number,
                readOnly: false
              })
            );
          } else if (this.currentStage()?.canRead) {
            this._router.navigateByUrl(
              this._getRoute({
                changeNoteId: this.overview()?.id as number,
                readOnly: true
              })
            );
          }
        }

        break;
      case 'COST_SOFT_SERVICES':
        if (this._activeStagesCount > 0) {
          if (
            this.currentStage()?.stageActive ||
            this.currentStage()?.stageCompleted
          ) {
            if (this.currentStage()?.canEdit) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: false
                })
              );
            } else if (this.currentStage()?.canRead) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: true
                })
              );
            }
          }
        }

        break;
      case 'COST_ENGINEERING_SERVICES':
        if (this._activeStagesCount > 0) {
          if (
            this.currentStage()?.stageActive ||
            this.currentStage()?.stageCompleted
          ) {
            if (this.currentStage()?.canEdit) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: false
                })
              );
            } else if (this.currentStage()?.canRead) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: true
                })
              );
            }
          }
        }
        break;
      case 'VERIFY_ASSET_LIST':
        if (this._activeStagesCount > 0) {
          if (
            this.currentStage()?.stageActive ||
            this.currentStage()?.stageCompleted
          ) {
            if (this.currentStage()?.canEdit) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: false
                })
              );
            } else if (this.currentStage()?.canRead) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: true
                })
              );
            }
          }
        }
        break;
      case 'GATHER_ASSET_PRICE':
        if (this._activeStagesCount > 0) {
          if (
            this.currentStage()?.stageActive ||
            this.currentStage()?.stageCompleted
          ) {
            if (this.currentStage()?.canEdit) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: false
                })
              );
            } else if (this.currentStage()?.canRead) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: true
                })
              );
            }
          }
        }
        break;
      case 'PROCUREMENT_TO_PRICE':
        if (this._activeStagesCount > 0) {
          if (
            this.currentStage()?.stageActive ||
            this.currentStage()?.stageCompleted
          ) {
            if (this.currentStage()?.canEdit) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: false
                })
              );
            } else if (this.currentStage()?.canRead) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: true
                })
              );
            }
          }
        }
        break;
      case 'PREPARE_ENGINEERING_QUOTE':
        if (this._activeStagesCount > 0) {
          if (
            this.currentStage()?.stageActive ||
            this.currentStage()?.stageCompleted
          ) {
            if (this.currentStage()?.canEdit) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: false
                })
              );
            } else if (this.currentStage()?.canRead) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: true
                })
              );
            }
          }
        }
        break;
      case 'ENGINEERING_REVIEW':
        if (this._activeStagesCount > 0) {
          if (
            this.currentStage()?.stageActive ||
            this.currentStage()?.stageCompleted
          ) {
            if (this.currentStage()?.canEdit) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: false
                })
              );
            } else if (this.currentStage()?.canRead) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: true
                })
              );
            }
          }
        }
        break;
      case 'GOVERNANCE_REVIEW':
        if (this._activeStagesCount > 0) {
          if (
            this.currentStage()?.stageActive ||
            this.currentStage()?.stageCompleted
          ) {
            if (this.currentStage()?.canEdit) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: false
                })
              );
            } else if (this.currentStage()?.canRead) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: true
                })
              );
            }
          }
        }
        break;
      case 'LEGAL_REVIEW':
        if (this._activeStagesCount > 0) {
          if (
            this.currentStage()?.stageActive ||
            this.currentStage()?.stageCompleted
          ) {
            if (this.currentStage()?.canEdit) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: false
                })
              );
            } else if (this.currentStage()?.canRead) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: true
                })
              );
            }
          }
        }
        break;
      case 'CUSTOMER_OPERATIONS_REVIEW':
        if (
          this.currentStage()?.stageActive ||
          this.currentStage()?.stageCompleted
        ) {
          this._router.navigateByUrl(
            `business-cases/${this.overview()?.id}/soft-services-quote`
          );
        }
        break;
      case 'CUSTOMER_ENGINEERING_REVIEW':
        if (this._activeStagesCount > 0) {
          if (
            this.currentStage()?.stageActive ||
            this.currentStage()?.stageCompleted
          ) {
            if (this.currentStage()?.canEdit) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: false
                })
              );
            } else if (this.currentStage()?.canRead) {
              this._router.navigateByUrl(
                this._getRoute({
                  changeNoteId: this.overview()?.id as number,
                  stage: this.currentStage()?.stageName,
                  readOnly: true
                })
              );
            }
          }
        }
        break;
      case 'CUSTOMER_FINAL_REVIEW':
        if (
          this.currentStage()?.stageActive ||
          this.currentStage()?.stageCompleted
        ) {
          this._router.navigateByUrl(
            `business-cases/${this.overview()?.id}/soft-services-quote`
          );
        }
        break;
      case 'GENERATE_CHANGE_NOTE':
        console.log('Still need to show what this is');
        break;
      default:
        break;
    }
  }

  private _getRoute(param: {
    changeNoteId: number;
    readOnly: boolean;
    stage?: string;
  }) {
    if (param.stage) {
      if (param.stage === BusinessCaseStagesEnum.COST_SOFT_SERVICES) {
        return `business-cases/${param.changeNoteId}/soft-services?stage=${param.stage}&&read-only=${param.readOnly}`;
      }

      if (param.stage === BusinessCaseStagesEnum.PREPARE_ENGINEERING_QUOTE ||
        param.stage === BusinessCaseStagesEnum.ENGINEERING_REVIEW) {
        return `business-cases/${param.changeNoteId}/engineering-services-quote?stage=${param.stage}&&read-only=${param.readOnly}`;
      }
      if (param.stage === BusinessCaseStagesEnum.GOVERNANCE_REVIEW ||
        param.stage === BusinessCaseStagesEnum.LEGAL_REVIEW ||
        param.stage === BusinessCaseStagesEnum.CUSTOMER_ENGINEERING_REVIEW) {
        return `business-cases/${param.changeNoteId}/governance-and-legal-overview?stage=${param.stage}&&read-only=${param.readOnly}`;
      }

      return `business-cases/${param.changeNoteId}/${param.stage
        .toLowerCase()
        .replace(/_/g, '-')}?stage=${param.stage}&&read-only=${param.readOnly}`;
    }

    return `business-cases/${param.changeNoteId}?read-only=${param.readOnly}`;
  }

  protected readonly BusinessCaseStageStatus = BusinessCaseStageStatus;
}
