<div class="w-full flex flex-row justify-between items-center p-4">
  <h4 class="font-semibold text-primary-blue inline-block">Reason for delay</h4>
  <!-- <button mat-icon-button matDialogClose>
    <mat-icon>clear</mat-icon>
  </button> -->
</div>
<div matDialogContent>
  <div class="flex flex-col">
    <p class="mb-8">
      A delay notice has been flagged for {{ data.stageName | transformEnumToSentenceCase }}. Give reason
    </p>

    <form [formGroup]="reasonForDelayForm" (ngSubmit)="submitForm()">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Select Reason</mat-label>
        <mat-select formControlName="reasonForDelay">
          @for (reason of reasonsForDelay; track $index) {
          <mat-option [value]="reason">{{ reason }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>More Details</mat-label>
        <textarea matInput formControlName="moreDetails" rows="8"></textarea>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Add new implementation date</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          formControlName="implementationDate"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <div class="py-2">
        <mat-divider></mat-divider>
      </div>

      <div>
        <div class="flex items-center justify-center w-full">
          <label
            bidvestDragAndDrop
            (fileDropped)="dragAndDropFile($event)"
            class="flex flex-col items-center justify-center w-full h-52 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
          >
            <div
              class="flex flex-col items-center justify-center pt-5 pb-6 px-4"
            >
              <svg
                class="w-8 h-8 mb-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p class="mb-2 text-sm text-gray-500 text-center">
                <span class="font-semibold">Click to upload</span>
                <br />
                <span>or</span>
                <br />
                <span>drag and drop</span>
              </p>
              <p class="text-xs text-gray-500 overflow-ellipsis">
                {{ uploadState.fileName }}
              </p>
            </div>
            <input
              (change)="onFileSelect($event)"
              [accept]="allowedMimeTypes"
              placeholder=" "
              #fileInput
              type="file"
              class="hidden"
            />
          </label>
        </div>
      </div>

      <button class="hidden" #submitBtn></button>
    </form>
  </div>
</div>
<div class="grid grid-cols-1 px-4 pb-6">
  <button
    mat-flat-button
    color="accent"
    [disabled]="!reasonForDelayForm.valid"
    (click)="submitBtn.click()"
  >
    Update
  </button>
</div>
