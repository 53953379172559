<div>
  <div *ngIf="showTable" class="flex flex-col justify-start">
    <h4 class="text-2xl font-bold text-primary-blue">
      Asset list verification
    </h4>
    <p class="sub-heading-text">
      Confirm existing asset list and add assets that are not on the list but on
      the site
    </p>
  </div>
  <div class="flex flex-row justify-between mt-4">
    <div *ngIf="showTable" class="flex flex-col justify-center">
      <div class="flex flex-row gap-4">
        <mat-form-field class="search-bar" appearance="outline">
          <mat-label>Search</mat-label>
          <input
            class="h-[1vh]"
            type="text"
            [(ngModel)]="searchTerm"
            matInput
            placeholder="Search"
            name="searchTerm"
          />
          <mat-icon (click)="searchOnClick()" matSuffix>search</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Filter</mat-label>
          <mat-select>
            <mat-option [value]="" (click)="applyFilter('')">ALL</mat-option>
            @for (disciplines of filterOptions; track disciplines) {
            <mat-option
              [value]="disciplines"
              (click)="applyFilter(disciplines)"
              >{{ disciplines }}</mat-option
            >
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="flex flex-row justify-center pb-3">
      <div *ngIf="showTable" class="flex flex-col">
        <button class="bidvest-btn mr-4" (click)="showActivityLogDialog()">
          <mat-icon>add</mat-icon>
          Add comment
        </button>
      </div>
      <div class="flex flex-col">
        <button class="bidvest-stroked-btn" (click)="showAssetListTable()">
          <mat-icon>visibility</mat-icon>
          {{ showTable ? 'Show Drawing' : 'Show Table' }}
        </button>
      </div>
    </div>
  </div>
  <div class="rounded justify-between items-center w-full" *ngIf="!showTable">
    <bidvest-pdf-drawing-view></bidvest-pdf-drawing-view>
  </div>
  <div
    class="flex rounded-t-lg overflow-x-scroll overflow-y-scroll border-x-[1px] justify-center"
    *ngIf="showTable"
  >
    <div
      class="flex flex-row rounded-t-lg w-full h-[100vh] border-x-[1px] border-b-[1px]"
    >
      <table mat-table [dataSource]="dataSource" appearance="outline">
        <ng-container matColumnDef="mandate">
          <th mat-header-cell *matHeaderCellDef>Mandate</th>
          <td mat-cell *matCellDef="let element">{{ element.mandate }}</td>
        </ng-container>

        <ng-container matColumnDef="siteId">
          <th mat-header-cell *matHeaderCellDef>Site ID</th>
          <td mat-cell *matCellDef="let element">{{ element.siteId }}</td>
        </ng-container>

        <ng-container matColumnDef="equipmentId">
          <th mat-header-cell *matHeaderCellDef>Equipment ID</th>
          <td mat-cell *matCellDef="let element">
            {{ element.equipmentId }}
          </td>
        </ng-container>

        <ng-container matColumnDef="strategy">
          <th mat-header-cell *matHeaderCellDef>Strategy</th>
          <td mat-cell *matCellDef="let element">{{ element.strategy }}</td>
        </ng-container>

        <ng-container matColumnDef="implementationDate">
          <th mat-header-cell *matHeaderCellDef>Implementation Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.implementationDate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="templateNumber">
          <th mat-header-cell *matHeaderCellDef>Template number</th>
          <td mat-cell *matCellDef="let element">
            {{ element.templateNumber }}
          </td>
        </ng-container>

        <ng-container matColumnDef="discipline">
          <th mat-header-cell *matHeaderCellDef>Discipline</th>
          <td mat-cell *matCellDef="let element">
            {{ element.discipline }}
          </td>
        </ng-container>

        <ng-container matColumnDef="equipmentText">
          <th mat-header-cell *matHeaderCellDef>Equipment text</th>
          <td mat-cell *matCellDef="let element">
            {{ element.equipmentText }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <div class="w-full">
              <div
                class="w-full"
                *ngIf="element?.seen && element?.assetSeen !== null"
              >
                <span
                  *ngIf="element?.assetSeen?.assetSeenStatus === 'REPLACE'"
                  class="bg-[#B8B800] px-4 py-2 text-white font-semibold text-xs rounded-full w-full"
                >
                  {{ element?.assetSeen?.assetSeenStatus | transformEnumToSentenceCase }}
                </span>
                <span
                  *ngIf="element?.assetSeen?.assetSeenStatus === 'NEW_FIRST_TIME_INSTALLATION'"
                  class="bg-yellow-400 px-4 py-2 text-white font-semibold text-xs rounded-full w-full"
                >
                  {{ element?.assetSeen?.assetSeenStatus | transformEnumToSentenceCase }}
                </span>
                <span
                  *ngIf="element?.assetSeen?.assetSeenStatus === 'REMOVED'"
                  class="bg-red-400 px-4 py-2 text-white font-semibold text-xs rounded-full w-full"
                >
                  {{ element?.assetSeen?.assetSeenStatus | transformEnumToSentenceCase }}
                </span>
                <span
                  *ngIf="element?.assetSeen?.assetSeenStatus === 'REMAIN'"
                  class="bg-bidvest-green px-4 py-2 text-white font-semibold text-xs rounded-full w-full"
                >
                  {{ element?.assetSeen?.assetSeenStatus | transformEnumToSentenceCase }}
                </span>
              </div>
              <span
                *ngIf="!element?.seen && element?.assetNotSeen !== null"
                class="bg-red-600 px-4 py-2 text-white font-semibold text-xs rounded-full w-full"
              >
                Not seen
              </span>
              <span
                *ngIf="
                  element?.assetSeen === null && element?.assetNotSeen === null
                "
                class="bg-red-600 px-4 py-2 text-white font-semibold text-xs rounded-full w-full"
              >
                Not Verified
              </span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div>
              <button
                (click)="markAssetAsSeen(element)"
                [disabled]="element.seen"
                mat-icon-button
                class="mr-4 font-bold stroke-2"
              >
                <mat-icon
                  class="text-sm font-bold"
                  [ngClass]="element.seen ? 'text-green-500' : ''"
                >
                  done
                </mat-icon>
              </button>
              <button
                (click)="markAssetAsUnseen(element)"
                [disabled]="!element.seen && element.assetNotSeen !== null"
                mat-icon-button
              >
                <mat-icon
                  class="text-sm font-bold"
                  [ngClass]="
                    !element.seen && element.assetNotSeen !== null
                      ? 'text-red-500'
                      : ''
                  "
                >
                  clear
                </mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
