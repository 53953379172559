<span
  class="flex flex-col justify-center items-center mb-4 last:mb-0.5 bg-slate-100 hover:bg-slate-300 hover:cursor-pointer min-w-[14rem] p-4 group rounded"
  [style.border]="currentStage()?.stageCompleted ? '1px solid green' : ''"
  (click)="goToStageView(currentStage()!)"
>
  @switch (currentStage()?.businessCaseStageStatus) {
      @case (BusinessCaseStageStatus.APPROVED) {
        <img src="assets/approved.svg" class="w-6 h-6" alt="" />
      }
      @case (BusinessCaseStageStatus.REJECTED) {
        <img src="assets/rejected.svg" class="w-6 h-6" alt="" />
      }
      @default { @if (currentStage()?.stageActive) {
        <img src="assets/active.svg" class="w-6 h-6" alt="" />
      }
      @else {
        <img src="assets/untouched.svg" class="w-6 h-6" alt="" />
      }
    }
  }
  <p class="font-semibold text-xs mt-4">
    {{ currentStage()?.stageName?.split('_')?.join(' ')}}
  </p>
</span>
