<div class="flex flex-row justify-between items-center p-4">
  <h4 class="font-semibold text-primary-blue text-xl">Replace Asset</h4>

  <button mat-icon-button class="text-gray-400" matDialogClose>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content class="flex flex-col">
  <form [formGroup]="form" class="flex flex-col justify-start gap-4">
    <span class="w-full mb-2">
      <h4 class="text-gray-300 font-semibold">Current</h4>
    </span>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Discipline</mat-label>
      <input
        type="text"
        matInput
        placeholder="Discipline"
        formControlName="currentEquipmentId"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Discipline</mat-label>
      <input
        type="text"
        matInput
        placeholder="Discipline"
        formControlName="currentEquipmentDiscipline"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-full">
      <mat-label>High Level Description</mat-label>
      <input
        type="text"
        matInput
        placeholder="High Level Description"
        formControlName="currentEquipmentHighLevelDescription"
      />
    </mat-form-field>

    <div class="my-4">
      <mat-divider></mat-divider>
    </div>

    <span class="w-full">
      <h4 class="text-gray-300 font-semibold">Replacement</h4>
    </span>

    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Discipline</mat-label>
      <input
        type="text"
        matInput
        placeholder="Discipline"
        formControlName="discipline"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-full">
      <mat-label>High Level Description</mat-label>
      <input
        type="text"
        matInput
        placeholder="High Level Description"
        formControlName="highLevelDescription"
      />
    </mat-form-field>

    <div
      class="w-full grid grid-cols-12 gap-4"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
    >
      <mat-form-field appearance="outline" class="col-span-10">
        <mat-label>Equipment ID</mat-label>
        <input
          type="text"
          matInput
          placeholder="Equipment ID"
          formControlName="equipmentId"
          #equipmentIdControl
        />
      </mat-form-field>

      <span class="col-span-2 flex flex-col justify-center">
        <button class="bidvest-flat-btn" (click)="fetchEquipmentById()">
          Search
        </button>
      </span>
    </div>
  </form>

  @if (this.showQueryResults === true) {
  <mat-list>
    <mat-list-item
      *ngFor="let equipment of bcEquipments"
      (click)="onEquipmentSelect(equipment)"
    >
      {{ equipment.equipmentText }}({{ equipment.strategy }})
    </mat-list-item>
  </mat-list>
  }
</div>
<div matDialogActions>
  <button
    type="button"
    mat-flat-button
    color="accent"
    (click)="submitAssetReplacementForm()"
    [disabled]="selectedEquipment === undefined || selectedEquipment === null"
    class="w-full mx-6 my-4"
  >
    Replace
  </button>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="showQueryResults"
  (detach)="showQueryResults = false"
>
  <div
    class="bg-white p-6 w-[550px] h-auto max-h-40 overflow-y-auto rounded-b shadow-md"
  >
    @if (this.bcEquipments.length > 0) {
    <mat-action-list multiple="false">
      <mat-list-item
        *ngFor="let equipment of bcEquipments"
        disableRipple="false"
        (click)="onEquipmentSelect(equipment)"
      >
        {{ equipment.equipmentText }}({{ equipment.strategy }})
      </mat-list-item>
    </mat-action-list>
    } @else {
    <div class="w-full flex flex-row justify-center py-8">
      <h4 class="font-medium">Equipment not found</h4>
    </div>
    }
  </div>
</ng-template>
