<div class="flex flex-row justify-between items-center p-6">
  <h4 class="font-semibold text-primary-blue text-2xl">Create rate card</h4>
  <button mat-icon-button
  (click)="closeRateCardOverlay()"
  >
    <mat-icon
      class="cursor-pointer text-gray-500"
      >close</mat-icon
    >
  </button>
</div>
<div mat-dialog-content>
  <form [formGroup]="form" class="flex flex-col gap-4 mx-6">
    <mat-form-field class="w-full">
      <mat-label>Service name</mat-label>
      <input type="text" matInput formControlName="serviceName" />
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Service description</mat-label>
      <input type="text" matInput formControlName="serviceDescription" />
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Category</mat-label>
      <mat-select formControlName="category">
        @for (category of categories; track category) {
        <mat-option [value]="category.viewValue">{{
          category.value
        }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Sub-category</mat-label>
      <mat-select formControlName="subCategory">
        @for (subCategory of subCategories; track subCategory) {
        <mat-option [value]="subCategory.viewValue">{{
          subCategory.value
        }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Rate card group</mat-label>
      <mat-select formControlName="rateCardGroup">
        @for (rateCardgroup of rateCardGroup; track rateCardgroup) {
        <mat-option [value]="rateCardgroup.viewValue">{{
          rateCardgroup.value
        }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Unit cost</mat-label>
      <input type="text" matInput defaultValue="R" formControlName="unitCost" />
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>UOM</mat-label>
      <mat-select formControlName="unitOfMeasure">
        @for (unitsOfMeaurements of unitsOfMeaurement; track unitsOfMeaurements)
        {
        <mat-option [value]="unitsOfMeaurements.viewValue">{{
          unitsOfMeaurements.value
        }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Notes</mat-label>
      <textarea type="text" matInput formControlName="notes"></textarea>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Vendor name</mat-label>
      <input type="text" matInput formControlName="vendorName" />
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Valid until </mat-label>

      <input
        matInput
        [min]="minDate"
        [matDatepicker]="picker"
        formControlName="validTillDate"
      />

      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="b-t-2 b-solid b-gray-300">
  <button
    (click)="addNewRateCard()"
    mat-flat-button
    color="accent"
    [disabled]="!form.valid"
    class="w-full my-4 mx-6"
  >
    Submit
  </button>
</div>
